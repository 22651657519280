/* eslint-disable global-require */
import { OrderStatus, GatewayTypesEnum } from "@globalTypes/globalTypes";
import { generatePageUrl } from "./utils";
export var BASE_URL = "/";
export var PRODUCTS_PER_PAGE = 100;
export var SUPPORT_EMAIL = "support@example.com";
export var PROVIDERS = {
  BRAINTREE: {
    label: "Braintree"
  },
  DUMMY: {
    label: "Dummy"
  },
  ADYEN: {
    label: "Adyen",
    script: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.js",
      integrity: "sha384-wG2z9zSQo61EIvyXmiFCo+zB3y0ZB4hsrXVcANmpP8HLthjoQJQPBh7tZKJSV8jA",
      crossOrigin: "anonymous"
    },
    style: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.css",
      integrity: "sha384-8ofgICZZ/k5cC5N7xegqFZOA73H9RQ7H13439JfAZW8Gj3qjuKL2isaTD3GMIhDE",
      crossOrigin: "anonymous"
    }
  },
  CASH: {
    gatewayId: GatewayTypesEnum.CASH,
    key: "Cash",
    label: "Pago en Efectivo"
  },
  DATAPHONE: {
    gatewayId: GatewayTypesEnum.DATAPHONE,
    key: "Dataphone",
    label: "Pago con tarjeta (Datáfono)"
  },
  NEQUI: {
    gatewayId: GatewayTypesEnum.NEQUI,
    key: "Nequi",
    label: "Pago con Nequi"
  },
  ONLINE_CREDIBANCO: {
    gatewayId: GatewayTypesEnum.ONLINE_CREDIBANCO,
    key: "Online",
    label: "Pago en línea"
  }
};
export var STATIC_PAGES = [{
  label: "About",
  url: generatePageUrl("about")
}];
export var SOCIAL_MEDIA = [
  /* {
    ariaLabel: "facebook",
    href: "https://www.facebook.com/Luzattcol",
    path: require("../images/facebook-icon.svg"),
  },
  {
    ariaLabel: "instagram",
    href: "https://www.instagram.com/luzatt_co/",
    path: require("../images/instagram-icon.svg"),
  },
   {
    ariaLabel: "twitter",
    href: "https://twitter.com/getsaleor",
    path: require("../images/twitter-icon.svg"),
  },
  {
    ariaLabel: "youtube",
    href: "https://www.youtube.com/channel/UCg_ptb-U75e7BprLCGS4s1g/videos",
    path: require("../images/youtube-icon.svg"),
  }, */
];
export var META_DEFAULTS = {
  custom: [],
  description: "EasyPedido, los domis de tu barrio en Whatsapp.",
  image: "".concat(window.location.origin).concat(require("../images/logo.svg")),
  title: "EasyPedido",
  type: "website",
  url: window.location.origin
};
export var CheckoutStep;

(function (CheckoutStep) {
  CheckoutStep[CheckoutStep["Address"] = 1] = "Address";
  CheckoutStep[CheckoutStep["Shipping"] = 2] = "Shipping";
  CheckoutStep[CheckoutStep["Payment"] = 3] = "Payment";
  CheckoutStep[CheckoutStep["Review"] = 4] = "Review";
  CheckoutStep[CheckoutStep["PaymentConfirm"] = 5] = "PaymentConfirm";
})(CheckoutStep || (CheckoutStep = {}));

export var CHECKOUT_STEPS = [{
  index: 0,
  link: "address",
  name: "Address",
  nextActionName: "Selecciona método de pago",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Address
}, {
  index: 1,
  link: "shipping",
  name: "Shipping",
  nextActionName: "Continuar",
  onlyIfShippingRequired: true,
  step: CheckoutStep.Shipping
}, {
  index: 2,
  link: "payment",
  name: "Payment",
  nextActionName: "Continuar",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Payment
}, {
  index: 3,
  link: "review",
  name: "Review",
  nextActionName: "Realizar Pedido",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Review
}, {
  index: 4,
  link: "/checkout/payment-confirm",
  name: "Confirmación de Pago",
  onlyIfShippingRequired: false,
  step: CheckoutStep.PaymentConfirm,
  withoutOwnView: true
}];
export var StatusStep;

(function (StatusStep) {
  StatusStep[StatusStep["Pending"] = 1] = "Pending";
  StatusStep[StatusStep["Fullfilled"] = 2] = "Fullfilled";
  StatusStep[StatusStep["Sent"] = 3] = "Sent";
  StatusStep[StatusStep["Arrived"] = 4] = "Arrived";
})(StatusStep || (StatusStep = {}));

export var STATUS_STEPS = [{
  index: 0,
  link: "pending-draft",
  name: "Pending",
  status: [OrderStatus.PENDING, OrderStatus.DRAFT, OrderStatus.REFUSED_PAYMENT],
  step: StatusStep.Fullfilled
}, {
  index: 1,
  link: "fulfilled",
  name: "Fullfilled",
  status: [OrderStatus.FULFILLED, OrderStatus.SCHEDULED],
  step: StatusStep.Sent
}, {
  index: 2,
  link: "sent",
  name: "Sent",
  status: [OrderStatus.SENT, OrderStatus.CANCELED_FULFILLMENT],
  step: StatusStep.Arrived
}, {
  index: 3,
  link: "arrived",
  name: "Arrived",
  status: [OrderStatus.ARRIVED],
  step: "/"
}];
export var STATUS_PROBLEM_STEPS = [{
  index: 0,
  link: "timeout",
  name: "Timeout"
}, {
  index: 1,
  link: "canceled",
  name: "canceled"
}, {
  index: 2,
  link: "delivered",
  name: "delivered"
}, {
  index: 3,
  link: "undelivered",
  name: "undelivered"
}, {
  index: 4,
  link: "/pstatus/:token",
  name: "undelivered"
}, {
  index: 5,
  link: "CANCELLED_BY_CUSTOMER",
  name: "CANCELLED_BY_CUSTOMER"
}];