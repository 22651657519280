import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "@saleor/sdk";
import { CheckoutPage, ThankYouPage, StatusPage, AddressFormPage, NeighborhoodPage } from "@pages";
import UserAccount, * as accountPaths from "../../userAccount/routes";
import { OrderDetails } from "../../userAccount/views";
import { Account, AccountConfirm } from "../../views/Account";
import { CategoryPage } from "../../views/Category";
import { CategoriesPage } from "../../views/Categories";
import { CollectionPage } from "../../views/Collection";
import { HomeePage } from "../../views/Homee";
import { ProductPage } from "../../views/Product";
import { SalePage } from "../../views/Sale";
import { SaleCategoryPage } from "../../views/SaleCategory";
import { SearchPage } from "../../views/Search";
import { SubCategoryPage } from "../../views/subCategory";
import * as paths from "./paths";
export var RoutesCustomer = function RoutesCustomer() {
  var _useAuth = useAuth(),
      user = _useAuth.user;

  var requiredTradeRoutes = [React.createElement(Route, {
    path: paths.searchUrl,
    key: "search-page-1k",
    element: React.createElement(SearchPage, null)
  }), React.createElement(Route, {
    path: paths.categoryRedirectUrl,
    key: "category-page-2k",
    element: React.createElement(CategoryPage, null)
  }), React.createElement(Route, {
    path: paths.categoryUrl,
    key: "category-page-2k",
    element: React.createElement(CategoryPage, null)
  }), React.createElement(Route, {
    path: paths.categoriesUrl,
    key: "categories-page-3k",
    element: React.createElement(CategoriesPage, null)
  }), React.createElement(Route, {
    path: paths.subCategoryRedirectHomeUrl,
    key: "subcategory-page-4k",
    element: React.createElement(SubCategoryPage, null)
  }), React.createElement(Route, {
    path: paths.subCategoryRedirectUrl,
    key: "subcategory-page-4k",
    element: React.createElement(SubCategoryPage, null)
  }), React.createElement(Route, {
    path: paths.subCategoryUrl,
    key: "subcategory-page-4k",
    element: React.createElement(SubCategoryPage, null)
  }), React.createElement(Route, {
    path: paths.collectionUrl,
    key: "collection-page-5k",
    element: React.createElement(CollectionPage, null)
  }), React.createElement(Route, {
    path: paths.productEditUrl,
    key: "product-page-6k",
    element: React.createElement(ProductPage, null)
  }), React.createElement(Route, {
    path: paths.productUrl,
    key: "product-page-6k",
    element: React.createElement(ProductPage, null)
  }), React.createElement(Route, {
    path: paths.saleCategoryUrl,
    key: "salecategory-page-7k",
    element: React.createElement(SaleCategoryPage, null)
  }), React.createElement(Route, {
    path: paths.saleCategoryRedirectUrl,
    key: "salecategory-page-7k",
    element: React.createElement(SaleCategoryPage, null)
  }), React.createElement(Route, {
    path: paths.saleUrl,
    key: "sale-page-8k",
    element: React.createElement(SalePage, null)
  }), React.createElement(Route, {
    path: paths.checkoutUrl,
    key: "checkout-page-11k",
    element: React.createElement(CheckoutPage, null)
  })];
  var notRequiredTradeRoutes = [React.createElement(Route, {
    path: paths.neighborhoodsUrl,
    key: "neighborhoods-26k",
    element: React.createElement(NeighborhoodPage, null)
  }), React.createElement(Route, {
    path: accountPaths.userOrderDetailsUrl,
    key: "order-details-12k",
    element: React.createElement(OrderDetails, null)
  }), React.createElement(Route, {
    path: paths.guestOrderDetailsUrl,
    key: "order-details-13k",
    element: React.createElement(OrderDetails, null)
  }), React.createElement(Route, {
    path: paths.accountUrl,
    key: "account-14k",
    element: React.createElement(Account, null)
  }), React.createElement(Route, {
    path: paths.accountConfirmUrl,
    key: "account-confirm-15k",
    element: React.createElement(AccountConfirm, null)
  }), React.createElement(Route, {
    path: paths.addressUrl,
    key: "address-form-page-16k",
    element: React.createElement(AddressFormPage, null)
  }), React.createElement(Route, {
    path: paths.orderHistoryUrl,
    key: "account-18k",
    element: React.createElement(Account, null)
  }), React.createElement(Route, {
    path: paths.orderInProgressUrl,
    key: "account-19k",
    element: React.createElement(Account, null)
  }), React.createElement(Route, {
    path: paths.addressBookUrl,
    key: "account-20k",
    element: React.createElement(Account, null)
  }), React.createElement(Route, {
    path: paths.statusTokenUrl,
    key: "status-page-21k",
    element: React.createElement(StatusPage, null)
  }), React.createElement(Route, {
    path: paths.statusUrl,
    key: "status-page-22k",
    element: React.createElement(StatusPage, null)
  }), React.createElement(Route, {
    path: paths.orderFinalizedUrl,
    key: "thankyou-page-23k",
    element: React.createElement(ThankYouPage, null)
  }), React.createElement(Route, {
    path: paths.statusPaymentUrl,
    key: "status-page-24k",
    element: React.createElement(StatusPage, null)
  })];

  if (user === null || user === void 0 ? void 0 : user.currentTrade) {
    return React.createElement(Routes, null, [].concat(requiredTradeRoutes, notRequiredTradeRoutes), React.createElement(Route, {
      path: accountPaths.baseUrl,
      element: React.createElement(UserAccount, null)
    }), React.createElement(Route, {
      path: paths.baseUrl,
      element: React.createElement(HomeePage, null)
    }), React.createElement(Route, {
      path: "/*",
      element: React.createElement(Navigate, {
        to: paths.baseUrl
      })
    }));
  }

  return React.createElement(Routes, null, notRequiredTradeRoutes, React.createElement(Route, {
    path: accountPaths.baseUrl,
    element: React.createElement(UserAccount, null)
  }), React.createElement(Route, {
    path: "/*",
    element: React.createElement(Navigate, {
      to: accountPaths.baseUrl
    })
  }));
};
export default RoutesCustomer;