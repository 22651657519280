import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ReactSVG from "react-svg";
import { Icon } from "@components/atoms";
import { Money } from "@components/containers";
import { Thumbnail, TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import { Link } from "react-router-dom";
import { useAuth } from "@saleor/sdk";
import { getDBIdFromGraphqlId, slugify } from "@temp/core/utils";
import * as S from "./styles";
import removeImg from "../../../images/basurita20x20.svg";

var editImg = require("../../../images/edit.svg");

var QuantityLeftButton = function QuantityLeftButton(subtract, index) {
  return React.createElement(S.QuantityButtons, {
    "data-test": "quantityControls"
  }, React.createElement("div", {
    onClick: subtract,
    "data-test": "subtractButton"
  }, React.createElement(Icon, {
    size: 16,
    name: "horizontal_line",
    color: "white"
  })));
};

var QuantityRightButton = function QuantityRightButton(add, index) {
  return React.createElement(S.QuantityButtons, {
    "data-test": "quantityControls"
  }, React.createElement("div", {
    onClick: add,
    "data-test": "increaseButton"
  }, React.createElement(Icon, {
    size: 16,
    name: "plus",
    color: "white"
  })));
};

var truncateName = function truncateName(name) {
  return name.length >= 25 ? "".concat(name.slice(0, 25), "...") : name;
};

export var CartRow = function CartRow(_ref) {
  var _line$variant10, _line$variant11, _line$variant12, _line$variant12$produ, _line$variant12$produ2, _line$totalPrice, _line$totalPriceUndis, _line$totalPrice2;

  var index = _ref.index,
      line = _ref.line,
      hide = _ref.hide,
      onDataChange = _ref.onDataChange,
      remove = _ref.remove;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useState = useState(line === null || line === void 0 ? void 0 : line.quantity.toString()),
      _useState2 = _slicedToArray(_useState, 2),
      tempQuantity = _useState2[0],
      setTempQuantity = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isTooMuch = _useState4[0],
      setIsTooMuch = _useState4[1];

  var intl = useIntl();
  var maxQuantity = React.useMemo(function () {
    var _line$variant;

    return line === null || line === void 0 ? void 0 : (_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : _line$variant.quantityAvailable;
  }, [line === null || line === void 0 ? void 0 : line.variant]);
  var key = React.useMemo(function () {
    return line.id ? "id-".concat(line.id) : "idx-".concat(index);
  }, [line.id]);
  var name = React.useMemo(function () {
    var _line$variant2, _line$variant3, _line$variant4, _line$variant4$produc, _line$variant5, _line$variant5$produc;

    return (line === null || line === void 0 ? void 0 : (_line$variant2 = line.variant) === null || _line$variant2 === void 0 ? void 0 : _line$variant2.name) ? truncateName(line === null || line === void 0 ? void 0 : (_line$variant3 = line.variant) === null || _line$variant3 === void 0 ? void 0 : _line$variant3.name) : (line === null || line === void 0 ? void 0 : (_line$variant4 = line.variant) === null || _line$variant4 === void 0 ? void 0 : (_line$variant4$produc = _line$variant4.product) === null || _line$variant4$produc === void 0 ? void 0 : _line$variant4$produc.name) ? truncateName(line === null || line === void 0 ? void 0 : (_line$variant5 = line.variant) === null || _line$variant5 === void 0 ? void 0 : (_line$variant5$produc = _line$variant5.product) === null || _line$variant5$produc === void 0 ? void 0 : _line$variant5$produc.name) : "";
  }, [line === null || line === void 0 ? void 0 : line.variant]);
  var withDiscount = React.useMemo(function () {
    var _line$variant6, _line$variant6$pricin, _line$variant6$pricin2, _line$variant6$pricin3, _line$variant7, _line$variant7$pricin, _line$variant7$pricin2, _line$variant7$pricin3;

    return (line === null || line === void 0 ? void 0 : (_line$variant6 = line.variant) === null || _line$variant6 === void 0 ? void 0 : (_line$variant6$pricin = _line$variant6.pricing) === null || _line$variant6$pricin === void 0 ? void 0 : (_line$variant6$pricin2 = _line$variant6$pricin.price) === null || _line$variant6$pricin2 === void 0 ? void 0 : (_line$variant6$pricin3 = _line$variant6$pricin2.net) === null || _line$variant6$pricin3 === void 0 ? void 0 : _line$variant6$pricin3.amount) !== (line === null || line === void 0 ? void 0 : (_line$variant7 = line.variant) === null || _line$variant7 === void 0 ? void 0 : (_line$variant7$pricin = _line$variant7.pricing) === null || _line$variant7$pricin === void 0 ? void 0 : (_line$variant7$pricin2 = _line$variant7$pricin.priceUndiscounted) === null || _line$variant7$pricin2 === void 0 ? void 0 : (_line$variant7$pricin3 = _line$variant7$pricin2.net) === null || _line$variant7$pricin3 === void 0 ? void 0 : _line$variant7$pricin3.amount);
  }, [line === null || line === void 0 ? void 0 : line.variant]);
  var allToppingsName = React.useMemo(function () {
    var _allToppingsNameInsid;

    var allToppingsNameInside = [];

    if (typeof line.attributes === "string") {
      allToppingsNameInside = JSON.parse(line === null || line === void 0 ? void 0 : line.attributes);
    } else {
      allToppingsNameInside = line === null || line === void 0 ? void 0 : line.attributes;
    }

    return (_allToppingsNameInsid = allToppingsNameInside) === null || _allToppingsNameInsid === void 0 ? void 0 : _allToppingsNameInsid.filter(function (topping) {
      return topping.totalAmount > 0;
    }).map(function (topping) {
      return topping.dataValues;
    }).reduce(function (a, b) {
      return a.concat(b);
    }, []).filter(function (value) {
      return value.quantity > 0;
    }).map(function (value) {
      return value.nameValue;
    }).join(", ");
  }, [line === null || line === void 0 ? void 0 : line.id]);
  var ellipsisToppingsName = React.useMemo(function () {
    return allToppingsName && allToppingsName.length >= 25 ? "".concat(allToppingsName.slice(0, 25), "...") : allToppingsName;
  }, [allToppingsName]);
  var quantityErrors = React.useMemo(function () {
    return isTooMuch ? [{
      message: intl.formatMessage(commonMessages.maxQtyIs, {
        maxQuantity: maxQuantity
      })
    }] : undefined;
  }, [isTooMuch]);
  var thumbnail = React.useMemo(function () {
    var _line$variant8, _line$variant8$produc, _user$currentTrade;

    return {
      thumbnail: (line === null || line === void 0 ? void 0 : (_line$variant8 = line.variant) === null || _line$variant8 === void 0 ? void 0 : (_line$variant8$produc = _line$variant8.product) === null || _line$variant8$produc === void 0 ? void 0 : _line$variant8$produc.thumbnail) || (user === null || user === void 0 ? void 0 : (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : _user$currentTrade.defaultProductImageThumbnail)
    };
  }, [line === null || line === void 0 ? void 0 : line.variant]);
  var handleBlurQuantityInput = React.useCallback(function () {
    var newQuantity = parseInt(tempQuantity, 10);

    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = line === null || line === void 0 ? void 0 : line.quantity;
    } else if (newQuantity > maxQuantity) {
      newQuantity = maxQuantity;
    }

    if ((line === null || line === void 0 ? void 0 : line.quantity) !== newQuantity) {
      var _line$variant9;

      onDataChange(line === null || line === void 0 ? void 0 : (_line$variant9 = line.variant) === null || _line$variant9 === void 0 ? void 0 : _line$variant9.id, newQuantity, line === null || line === void 0 ? void 0 : line.observation);
    }

    var newTempQuantity = newQuantity.toString();

    if (tempQuantity !== newTempQuantity) {
      setTempQuantity(newTempQuantity);
    }

    setIsTooMuch(false);
  }, [maxQuantity, line, tempQuantity]);
  var add = React.useCallback(function () {
    return (line === null || line === void 0 ? void 0 : line.quantity) < maxQuantity && onDataChange(line === null || line === void 0 ? void 0 : line.id, (line === null || line === void 0 ? void 0 : line.quantity) + 1, line === null || line === void 0 ? void 0 : line.observation);
  }, [line === null || line === void 0 ? void 0 : line.quantity]);
  var subtract = React.useCallback(function () {
    return (line === null || line === void 0 ? void 0 : line.quantity) > 1 && onDataChange(line === null || line === void 0 ? void 0 : line.id, (line === null || line === void 0 ? void 0 : line.quantity) - 1, line === null || line === void 0 ? void 0 : line.observation);
  }, [line === null || line === void 0 ? void 0 : line.quantity]);
  var handleQuantityChange = React.useCallback(function (evt) {
    var newQuantity = parseInt(evt.target.value, 10);
    setTempQuantity(evt.target.value);
    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  }, [maxQuantity]);
  useEffect(function () {
    setTempQuantity(line === null || line === void 0 ? void 0 : line.quantity.toString());
    return function () {
      setTempQuantity("0");
    };
  }, [line === null || line === void 0 ? void 0 : line.quantity]);
  return React.createElement("li", {
    key: key,
    className: "cart__list__item"
  }, React.createElement("div", {
    className: "cart__list__item__generalData",
    "data-test": "cartRow",
    "data-test-id": line === null || line === void 0 ? void 0 : (_line$variant10 = line.variant) === null || _line$variant10 === void 0 ? void 0 : _line$variant10.sku
  }, React.createElement("div", null, React.createElement(Link, {
    className: "cart__list__item__generalData__image",
    to: "/product/edit/".concat(slugify(name), "/").concat(getDBIdFromGraphqlId(line === null || line === void 0 ? void 0 : (_line$variant11 = line.variant) === null || _line$variant11 === void 0 ? void 0 : _line$variant11.id, "ProductVariant"), "/").concat(getDBIdFromGraphqlId(line === null || line === void 0 ? void 0 : (_line$variant12 = line.variant) === null || _line$variant12 === void 0 ? void 0 : (_line$variant12$produ = _line$variant12.product) === null || _line$variant12$produ === void 0 ? void 0 : (_line$variant12$produ2 = _line$variant12$produ.category) === null || _line$variant12$produ2 === void 0 ? void 0 : _line$variant12$produ2.id, "Category"), "/").concat(line === null || line === void 0 ? void 0 : line.id),
    onClick: hide
  }, React.createElement(ReactSVG, {
    className: "cart__list__item__generalData__image__edit",
    path: editImg
  }), React.createElement(Thumbnail, {
    source: thumbnail
  })), React.createElement("p", {
    "data-test": "price"
  }, withDiscount ? React.createElement(React.Fragment, null, React.createElement(Money, {
    money: line === null || line === void 0 ? void 0 : (_line$totalPrice = line.totalPrice) === null || _line$totalPrice === void 0 ? void 0 : _line$totalPrice.net
  }), React.createElement("span", {
    className: "cart__list__item__generalData__withoutdiscount"
  }, React.createElement(Money, {
    money: line === null || line === void 0 ? void 0 : (_line$totalPriceUndis = line.totalPriceUndiscounted) === null || _line$totalPriceUndis === void 0 ? void 0 : _line$totalPriceUndis.net
  }))) : React.createElement(Money, {
    money: line === null || line === void 0 ? void 0 : (_line$totalPrice2 = line.totalPrice) === null || _line$totalPrice2 === void 0 ? void 0 : _line$totalPrice2.net
  }))), React.createElement("div", {
    className: "cart__list__item__generalData__details"
  }, React.createElement("div", {
    className: "cart__list__item__generalData__details__top__container"
  }, React.createElement("div", null, React.createElement("p", {
    "data-test": "name",
    className: "cart__list__item__generalData__details__top__container__name"
  }, name), ellipsisToppingsName && React.createElement("p", {
    "data-test": "toppings",
    className: "cart__list__item__generalData__details__top__container__toppings"
  }, ellipsisToppingsName))), React.createElement("span", {
    className: "cart__list__item__generalData__details__container__delete-icon"
  }, React.createElement(S.Trash, null, React.createElement(ReactSVG, {
    path: removeImg,
    className: "cart__list__item__generalData__details__delete-icon",
    "data-test": "deleteButton",
    onClick: function onClick() {
      return remove(line === null || line === void 0 ? void 0 : line.id);
    }
  })), React.createElement(S.Quantity, null, React.createElement(TextField, {
    name: "quantity",
    label: intl.formatMessage(commonMessages.qty),
    value: tempQuantity,
    onBlur: handleBlurQuantityInput,
    onChange: handleQuantityChange,
    contentLeft: QuantityLeftButton(subtract, index),
    contentRight: QuantityRightButton(add, index),
    errors: quantityErrors,
    quantityInput: true
  }))))));
};