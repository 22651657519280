import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Formik } from "formik";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl"; // import { saveAs } from "file-saver";

import { Input } from "@components/atoms";
import { checkoutMessages } from "@temp/intl";
import { AlertPayment } from "../../../../components/AlertPayment";
import { SelectBills } from "../../../../components/SelectBills"; // import { useCart } from "@saleor/sdk";

import * as S from "./styles";

// const NUMBER_KEY: string = "shopper_phone_number";
// const QR_URL_KEY: string = "shopper_qr_url";
// const SHOPPER_NAME_KEY: string = "shopper_account_name";

/**
 * CustomNequi payment gateway.
 */
var CashPaymentGateway = function CashPaymentGateway(_ref) {
  var changeSubmitProgress = _ref.changeSubmitProgress,
      processPayment = _ref.processPayment,
      formRef = _ref.formRef,
      formId = _ref.formId,
      onSelect = _ref.onSelect,
      initialStatus = _ref.initialStatus,
      config = _ref.config,
      totalPrice = _ref.totalPrice;
  // const getConfig = (key: string) => {
  //   const itemConfig = config.find((item: any) => item.field === key);
  //   return itemConfig?.value;
  // };
  // const downloadQR = () => {
  //   saveAs(getConfig(QR_URL_KEY), "nequi_qr_luzatt.jpg");
  // };
  var intl = useIntl();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      showAlert = _useState2[0],
      setShowAlert = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      showInput = _useState4[0],
      setShowInput = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      changeAction = _useState6[0],
      setChangeAction = _useState6[1];

  var _useState7 = useState({
    total: 0,
    totalPay: "0"
  }),
      _useState8 = _slicedToArray(_useState7, 2),
      totalPriceState = _useState8[0],
      setTotalPriceState = _useState8[1];

  var inputOnChange = function inputOnChange(e, setFieldValue) {
    var currencyLocal = "COP";
    var conversorValue = currencyLocal === "COP" ? "es-CO" : undefined;
    var maximumFractionsDigitsValue = currencyLocal === "COP" ? 0 : 2;
    var inputValue = e.target.value.replace(",", "").replace(/\./g, "").replace("$", "").replace("'", "").replace(" ", "").trim();

    if (isNaN(Number(inputValue))) {
      inputValue = inputValue.slice(0, inputValue.length - 1);
    }

    var moneyValue = Number(inputValue).toLocaleString(conversorValue, {
      currency: currencyLocal,
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionsDigitsValue
    });
    setFieldValue("reference_code", moneyValue);
  };

  return React.createElement(React.Fragment, null, React.createElement(Formik, {
    initialValues: {
      reference_code: initialStatus || ""
    },
    onSubmit: function onSubmit(values, _ref2) {
      var _totalPrice$net, _totalPrice$net2, _totalPrice$net3;

      var setSubmitting = _ref2.setSubmitting;

      if (changeSubmitProgress) {
        changeSubmitProgress(true);
      }

      var total = (totalPrice === null || totalPrice === void 0 ? void 0 : (_totalPrice$net = totalPrice.net) === null || _totalPrice$net === void 0 ? void 0 : _totalPrice$net.amount) ? Math.floor(totalPrice === null || totalPrice === void 0 ? void 0 : (_totalPrice$net2 = totalPrice.net) === null || _totalPrice$net2 === void 0 ? void 0 : _totalPrice$net2.amount) : totalPrice === null || totalPrice === void 0 ? void 0 : (_totalPrice$net3 = totalPrice.net) === null || _totalPrice$net3 === void 0 ? void 0 : _totalPrice$net3.amount;
      var totalPay = values.reference_code.replace(",", "").replace(/\./g, "").replace("$", "").replace(" ", "").trim();

      if (totalPay.length > 0) {
        if (total !== undefined && total !== null) {
          if (Number(totalPay) < total) {
            setTotalPriceState({
              total: total,
              totalPay: totalPay
            });
            setShowAlert(true);

            if (changeSubmitProgress) {
              changeSubmitProgress(false);
            }

            return;
          }
        }

        if (isNaN(Number(totalPay))) {
          processPayment("0");
        } else {
          processPayment(totalPay);
        }
      } else {
        processPayment("0");
      }

      setSubmitting(false); // changeSubmitProgress(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        setFieldValue = _ref3.setFieldValue,
        values = _ref3.values,
        isSubmitting = _ref3.isSubmitting,
        isValid = _ref3.isValid;
    return React.createElement(S.Form, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit,
      "data-test": "cashPaymentGatewayForm"
    }, React.createElement(S.Instructions, null, React.createElement(S.Paragraph, null, React.createElement(FormattedMessage, {
      id: "@next.components.organisms.CashPaymentGateway.CashPaymentGateway.2537429541",
      defaultMessage: "Indicate how much money you are going to pay to prepare your return "
    }))), React.createElement(S.Field, null, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "@next.components.organisms.CashPaymentGateway.CashPaymentGateway.3522249207",
      defaultMessage: "Pay with"
    }), ": "), React.createElement(SelectBills, {
      changeAction: changeAction,
      setFieldValue: setFieldValue,
      values: values,
      showInput: showInput,
      setShowInput: setShowInput
    }), showInput && React.createElement(React.Fragment, null, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "@next.components.organisms.CashPaymentGateway.CashPaymentGateway.2545929012",
      defaultMessage: "Enter value"
    }), ": "), React.createElement(Input, {
      type: "tel",
      key: "reference_code",
      name: "reference_code",
      label: "",
      placeholder: intl.formatMessage(_defineProperty({
        defaultMessage: "Value",
        "id": "@next.components.organisms.CashPaymentGateway.CashPaymentGateway.1148029984"
      }, "id", "@next.components.organisms.CashPaymentGateway.CashPaymentGateway.1148029984")),
      autoFocus: true,
      value: values.reference_code === intl.formatMessage(checkoutMessages.other) ? "" : values.reference_code,
      onChange: function onChange(e) {
        inputOnChange(e, setFieldValue);
      }
    }))));
  }), showAlert && React.createElement(AlertPayment, {
    totalPrice: totalPriceState,
    hideModal: function hideModal() {
      setShowAlert(false);
      localStorage.setItem("flag-input-other-values", "true");
      setChangeAction(!changeAction);
    }
  }), React.createElement("div", {
    id: "inputOtherValues"
  }));
};

export { CashPaymentGateway };