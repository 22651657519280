import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { StatusData, StatusDataFooter } from "@components/molecules";
import { OrderStatus } from "@globalTypes/globalTypes";
import * as S from "./styles";

var orderNoApprovedImg = require("../../../../images/noApproved.svg");

var goBackImg = require("../../../../images/arrowBack.svg");

/**
 * Canceled status structure
 */
var StatusCanceled = function StatusCanceled(_ref) {
  var data = _ref.data,
      goBack = _ref.goBack;
  return React.createElement(S.Wrapper, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(S.BackTitle, {
    onClick: goBack
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.3471378517",
    defaultMessage: "back"
  }))), (data === null || data === void 0 ? void 0 : data.status) === OrderStatus.CANCELED ? React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.158747495",
    defaultMessage: "request not approved"
  }) : (data === null || data === void 0 ? void 0 : data.status) === OrderStatus.CANCELLED_BY_CUSTOMER ? React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.2056573607",
    defaultMessage: "request canceled"
  }) : (data === null || data === void 0 ? void 0 : data.status) === OrderStatus.CANCELLED_BY_SYSTEM && React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.2466400544",
    defaultMessage: "cancelled by system"
  })))), React.createElement(S.Content, null, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Title, null, (data === null || data === void 0 ? void 0 : data.status) === OrderStatus.CANCELED ? React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.158747495",
    defaultMessage: "request not approved"
  }) : (data === null || data === void 0 ? void 0 : data.status) === OrderStatus.CANCELLED_BY_CUSTOMER ? React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.2056573607",
    defaultMessage: "request canceled"
  }) : (data === null || data === void 0 ? void 0 : data.status) === OrderStatus.CANCELLED_BY_SYSTEM && React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.1975914456",
    defaultMessage: "canceled by system"
  }))), React.createElement(S.ContentContainer, null, React.createElement(S.Images, null, React.createElement(ReactSVG, {
    path: orderNoApprovedImg
  })), (data === null || data === void 0 ? void 0 : data.paymentStatus) === "FULLY_REFUNDED" && (data === null || data === void 0 ? void 0 : data.status) === OrderStatus.CANCELLED_BY_CUSTOMER && React.createElement(React.Fragment, null, React.createElement(S.Parragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.2017701232",
    defaultMessage: "you cancelled the order"
  })), React.createElement(S.ParragraphLetter, null, React.createElement(S.SpanSpecial, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.367727008",
    defaultMessage: "Your payment has been refunded"
  })))), (data === null || data === void 0 ? void 0 : data.paymentStatus) === "REFUND_FAILED" && (data === null || data === void 0 ? void 0 : data.status) === OrderStatus.CANCELLED_BY_CUSTOMER && React.createElement(React.Fragment, null, React.createElement(S.Parragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.2017701232",
    defaultMessage: "you cancelled the order"
  })), React.createElement(S.ParragraphLetter, null, React.createElement(S.SpanSpecial, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.1448548847",
    defaultMessage: "We are processing the refund"
  })))), (data === null || data === void 0 ? void 0 : data.status) === OrderStatus.CANCELLED_BY_SYSTEM && React.createElement(React.Fragment, null, React.createElement(S.ParragraphTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.2349105125",
    defaultMessage: "the order has been canceled by the system"
  }))), (data === null || data === void 0 ? void 0 : data.status) === OrderStatus.CANCELED && React.createElement(React.Fragment, null, React.createElement(S.ParragraphTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.683885602",
    defaultMessage: "the establishment has not approved the order by"
  })), data.cancellationReason && React.createElement(S.Parragraph, null, React.createElement(S.Span, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusCanceled.StatusCanceled.3077186403",
    defaultMessage: "Reason"
  }), ": "), data.cancellationReason)))), React.createElement(S.Aside, null, React.createElement(StatusData, {
    deliveryPhone: false,
    data: data,
    deliveryData: true
  })), React.createElement(S.Footer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(StatusDataFooter, {
    data: data
  }))));
};

export { StatusCanceled };