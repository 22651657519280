import React from "react";
import { Loader } from "@components/atoms";
import { Container } from "../Container";
import * as S from "./styles";

/**
 * Template for checkout page.
 */
var Status = function Status(_ref) {
  var activeStep = _ref.activeStep,
      loading = _ref.loading,
      navigation = _ref.navigation,
      status = _ref.status,
      paymentGateways = _ref.paymentGateways,
      _ref$hidePaymentGatew = _ref.hidePaymentGateways,
      hidePaymentGateways = _ref$hidePaymentGatew === void 0 ? false : _ref$hidePaymentGatew,
      cartSummary = _ref.cartSummary;
  return React.createElement(Container, null, loading && React.createElement(S.Loader, null, React.createElement(Loader, {
    fullScreen: true
  })), React.createElement(S.Wrapper, {
    navigation: !!navigation
  }, navigation && React.createElement(S.Navigation, null, navigation), React.createElement(S.Status, null, status)));
};

export { Status };