import "./scss/index.scss";
import * as React from "react";
import { checkoutMessages } from "@temp/intl";
import { useIntl } from "react-intl";
export var SelectBills = function SelectBills(_ref) {
  var changeAction = _ref.changeAction,
      setFieldValue = _ref.setFieldValue,
      values = _ref.values,
      showInput = _ref.showInput,
      setShowInput = _ref.setShowInput;
  var intl = useIntl();
  var bills = [{
    id: "bill1",
    value: "5000"
  }, {
    id: "bill2",
    value: "10000"
  }, {
    id: "bill3",
    value: "20000"
  }, {
    id: "bill4",
    value: "50000"
  }, {
    id: intl.formatMessage(checkoutMessages.other),
    value: intl.formatMessage(checkoutMessages.other)
  }];
  var currencyLocal = "COP";
  var conversorValue = currencyLocal === "COP" ? "es-CO" : undefined;
  var maximumFractionsDigitsValue = currencyLocal === "COP" ? 0 : 2;

  var selectBill = function selectBill(bill) {
    if (bill === intl.formatMessage(checkoutMessages.other)) {
      setShowInput(true);
    } else {
      setShowInput(false);
    }

    setFieldValue("reference_code", bill);
  };

  React.useEffect(function () {
    var list = document.getElementsByClassName("billsContainer__bill");

    for (var i = 0; i <= list.length; i++) {
      if (list[i] !== undefined) {
        list[i].classList.remove("billsContainer__bill--active");
      }
    }

    var inputValue = values.reference_code.replace(",", "").replace(".", "").replace("$", "").replace("'", "").replace(" ", "").trim();
    var indexBill = bills.findIndex(function (data) {
      if (data.value === inputValue) {
        return true;
      }
    });

    if (indexBill !== -1) {
      document.getElementById(bills[indexBill].id).classList.add("billsContainer__bill--active");
    } else if (showInput) {
      document.getElementById(intl.formatMessage(checkoutMessages.other)).classList.add("billsContainer__bill--active");
    }

    return function () {};
  }, [values.reference_code]);
  React.useEffect(function () {
    var flagInput = localStorage.getItem("flag-input-other-values");

    if (flagInput === "true") {
      selectBill(intl.formatMessage(checkoutMessages.other));
    }

    return function () {
      selectBill("");
    };
  }, [changeAction]);
  var showBills = bills.map(function (_ref2, index) {
    var id = _ref2.id,
        value = _ref2.value;
    var moneyValue;

    if (isNaN(Number(value))) {
      moneyValue = intl.formatMessage(checkoutMessages.other);
    } else {
      moneyValue = Number(value).toLocaleString(conversorValue, {
        currency: currencyLocal,
        style: "currency",
        minimumFractionDigits: 0,
        maximumFractionDigits: maximumFractionsDigitsValue
      });
    }

    return React.createElement("span", {
      id: id,
      key: id,
      className: "billsContainer__bill",
      onClick: function onClick() {
        selectBill(moneyValue);
      }
    }, moneyValue);
  });
  return React.createElement("div", {
    className: "billsContainer"
  }, showBills);
};
export default SelectBills;