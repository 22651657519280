import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/* eslint-disable jsx-a11y/label-has-associated-control */
import "./scss/index.scss";
import React, { useState } from "react";
import ReactSVG from "react-svg";
import { FormattedMessage, useIntl } from "react-intl";
import { animated, useSpring } from "react-spring";
import { Button, Input } from "@components/atoms";
import { useAuth } from "@saleor/sdk/";
import { InputSelect } from "@app/components/molecules";
import { GatewayTypesEnum } from "@globalTypes/globalTypes";
import { checkoutMessages, paymentMessages } from "@temp/intl";
import { paymentMethodOptions } from "@temp/core/utils";
import SelectBills from "../SelectBills";

var closeWhite = require("../../images/closewhite.svg");

export var AlertChangePaymentMethod = function AlertChangePaymentMethod(_ref) {
  var _paymentMethodOptions, _user$currentTrade;

  var currentMethodPayment = _ref.currentMethodPayment,
      handleChangePaymentMethod = _ref.handleChangePaymentMethod,
      hideModal = _ref.hideModal;
  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      showInput = _useState2[0],
      setShowInput = _useState2[1];

  var _useState3 = useState(""),
      _useState4 = _slicedToArray(_useState3, 2),
      token = _useState4[0],
      setToken = _useState4[1];

  var _useState5 = useState({
    id: currentMethodPayment,
    name: (_paymentMethodOptions = paymentMethodOptions(intl).find(function (availableMethodPayment) {
      return availableMethodPayment.id === currentMethodPayment;
    })) === null || _paymentMethodOptions === void 0 ? void 0 : _paymentMethodOptions.name
  }),
      _useState6 = _slicedToArray(_useState5, 2),
      paymentMethodState = _useState6[0],
      setPaymentMethodState = _useState6[1];

  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });

  var handleTokenChange = function handleTokenChange(e) {
    var currencyLocal = "COP";
    var conversorValue = currencyLocal === "COP" ? "es-CO" : undefined;
    var maximumFractionsDigitsValue = currencyLocal === "COP" ? 0 : 2;
    var inputValue = e.target.value.replace(",", "").replace(/\./g, "").replace("$", "").replace("'", "").replace(" ", "").trim();

    if (isNaN(Number(inputValue))) {
      inputValue = inputValue.slice(0, inputValue.length - 1);
    }

    var moneyValue = Number(inputValue).toLocaleString(conversorValue, {
      currency: currencyLocal,
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionsDigitsValue
    });
    setToken(moneyValue);
  };

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "changePaymentMethod",
    onClick: hideModal
  }), React.createElement("div", {
    className: "changePaymentMethod__data"
  }, React.createElement("div", {
    className: "changePaymentMethod__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertChangePaymentMethod.index.826811448",
    defaultMessage: "Change payment method"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "changePaymentMethod__data__content  changePaymentMethod__data__content--amount changePaymentMethod__data__content--first changePaymentMethod__data__content--last"
  }, React.createElement("p", {
    className: "changePaymentMethod__data__content__question"
  }, React.createElement(FormattedMessage, {
    id: "components.AlertChangePaymentMethod.index.3886263156",
    defaultMessage: "Select the new payment method"
  })), React.createElement(InputSelect, {
    label: intl.formatMessage(paymentMessages.paymentMethod),
    name: "paymentMethod",
    defaultValue: currentMethodPayment,
    options: user === null || user === void 0 ? void 0 : (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : _user$currentTrade.availablePaymentGateways.map(function (paymentMethod) {
      var name = "";

      if (paymentMethod.id === GatewayTypesEnum.CASH) {
        name = intl.formatMessage(paymentMessages.cash);
      } else if (paymentMethod.id === GatewayTypesEnum.DATAPHONE) {
        name = intl.formatMessage(paymentMessages.card);
      } else if (paymentMethod.id === GatewayTypesEnum.NEQUI) {
        name = intl.formatMessage(paymentMessages.nequi);
      } else if (paymentMethod.id === GatewayTypesEnum.ONLINE_CREDIBANCO) {
        name = intl.formatMessage(paymentMessages.onlineCredibanco);
      }

      return {
        id: paymentMethod.id,
        name: name
      };
    }),
    value: paymentMethodState,
    onChange: function onChange(value) {
      setPaymentMethodState({
        id: value.id,
        name: value.name
      });
    },
    optionLabelKey: "name",
    optionValueKey: "id"
  }), paymentMethodState.id === GatewayTypesEnum.CASH && React.createElement(React.Fragment, null, React.createElement(SelectBills, {
    changeAction: false,
    setFieldValue: function setFieldValue(_set, tokenValue) {
      return setToken(tokenValue);
    },
    values: {
      reference_code: token
    },
    showInput: showInput,
    setShowInput: setShowInput
  }), showInput && React.createElement(React.Fragment, null, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertChangePaymentMethod.index.2545929012",
    defaultMessage: "Enter value"
  }), ": "), React.createElement(Input, {
    type: "tel",
    key: "reference_code",
    name: "reference_code",
    label: "",
    placeholder: intl.formatMessage(_defineProperty({
      defaultMessage: "Value",
      "id": "components.AlertChangePaymentMethod.index.1148029984"
    }, "id", "components.AlertChangePaymentMethod.index.1148029984")),
    autoFocus: true,
    value: token === intl.formatMessage(checkoutMessages.other) ? "" : token,
    onChange: handleTokenChange
  })))), React.createElement("div", {
    className: "changePaymentMethod__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertChangePaymentMethod.index.2680335018",
    defaultMessage: "No"
  })), React.createElement(animated.button, {
    className: "changePaymentMethod__data__footer__p",
    type: "button",
    onClick: function onClick() {
      handleChangePaymentMethod(paymentMethodState.id, token || paymentMethodState.id);
    },
    style: scaleButton
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertChangePaymentMethod.index.1473195966",
    defaultMessage: "Change"
  }))))));
};
export default AlertChangePaymentMethod;