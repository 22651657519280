import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
export var Timer = function Timer(_ref) {
  var maxMinutes = _ref.maxMinutes,
      maxSeconds = _ref.maxSeconds,
      lastUpdatedAt = _ref.lastUpdatedAt,
      onFinishTimer = _ref.onFinishTimer;
  maxSeconds = maxSeconds || 59;

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      seconds = _useState2[0],
      setSeconds = _useState2[1];

  var _useState3 = useState(10),
      _useState4 = _slicedToArray(_useState3, 2),
      minutes = _useState4[0],
      setMinutes = _useState4[1];

  useEffect(function () {
    var processTimer = function processTimer() {
      var currentTime = new Date();
      var orderTime = new Date(lastUpdatedAt);
      var minutesBetweenDates = (currentTime.getTime() - orderTime.getTime()) / 1000 / 60;
      var currentMinutes = maxMinutes - Math.floor(minutesBetweenDates);
      var currentSeconds = maxSeconds - Math.round((minutesBetweenDates - Math.floor(minutesBetweenDates)) * 60);
      setSeconds(currentSeconds > 0 ? currentSeconds : 0);
      setMinutes(currentMinutes > 0 ? currentMinutes : 0);

      if (onFinishTimer && currentMinutes + currentSeconds < 0) {
        clearInterval(time);
        onFinishTimer();
      }
    };

    processTimer();
    var time = setInterval(function () {
      processTimer();
    }, 1000);
    return function () {
      clearInterval(time);
    };
  }, [lastUpdatedAt]);
  return React.createElement(S.Time, null, React.createElement(S.ParragraphTime, null, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.Timer.Timer.619535766",
    defaultMessage: "Maximum time"
  })), React.createElement(S.DataTime, null, minutes, ":", seconds < 10 ? "0".concat(seconds) : seconds, " ", React.createElement(FormattedMessage, {
    id: "@next.components.atoms.Timer.Timer.2612472964",
    defaultMessage: "Min"
  })));
};