import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 30px;\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 5px;\n  align-self: center;\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  color: #f76268;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  color: #afafaf;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-center: center;\n  font-weight: bold;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  line-height: 1.6;\n  font-size: ", ";\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  width: 90%;\n  margin: 0 auto;\n  font-size: 0.9rem;\n  span:nth-child(1) {\n    font-weight: bold;\n  }\n  span:nth-child(2) {\n    font-weight: lighter;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  div {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    width: 35px;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  div {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  div {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(0.7);\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 10px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding: 0.5rem 1rem;\n  margin-top: 0.6rem;\n  font-size: ", ";\n  background-color: #efeff2;\n  border-radius: 10px;\n  color: #f76268;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1.5rem;\n  font-weight: ", ";\n  text-align: center;\n  padding: 0 0 0.5rem 0;\n  margin-bottom: 1rem;\n  border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 0 0 20px 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: repeat(1, 1fr);\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 30px;\n  grid-template-columns: repeat(2, 1fr);\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 90px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Grid = styled.div(_templateObject2(), media.xMediumScreen(_templateObject3()));
export var Divider = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject5(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var SubTitle = styled.h4(_templateObject6(), function (props) {
  return props.theme.typography.baseFontSize;
});
export var Container = styled.div(_templateObject7());
export var ImageDataphone = styled.div(_templateObject8());
export var Image = styled.div(_templateObject9());
export var ImageOnlineCredibanco = styled.div(_templateObject10());
export var Label = styled.div(_templateObject11());
export var TextSummary = styled.p(_templateObject12(), function (props) {
  return props.theme.typography.h4FontSize;
});
export var ParragraphTitle = styled.div(_templateObject13());
export var Parragraph = styled.p(_templateObject14());
export var Token = styled.span(_templateObject15());
export var Text = styled.p(_templateObject16());
export var ErrorMessages = styled.div(_templateObject17());