import { useOrdersByUserBasicData } from "@saleor/sdk/";
import React, { useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import { Loader } from "@components/atoms";
import { OrderTable } from "@components/organisms/OrderTable";
import { Footer } from "@temp/components";
import { perPage } from "@temp/constants";
import { OrderStatus } from "@temp/globalTypes";
import { addOrders, useAppDispatch } from "@temp/redux";
import * as S from "./styles";

var inciteBuy = require("../../../images/inciteBuy.svg");

var ORDERS_PER_APICALL = Number(perPage);
export var OrdersHistory = function OrdersHistory(_ref) {
  var _data$pageInfo, _data$pageInfo2;

  var navigate = _ref.navigate;
  var dispatch = useAppDispatch();

  var _useOrdersByUserBasic = useOrdersByUserBasicData({
    perPage: ORDERS_PER_APICALL
  }, {
    fetchPolicy: !!localStorage.getItem("status_page") && !!localStorage.getItem("scroll_order_list") && localStorage.getItem("status_page") === "true" ? "cache-first" : "cache-and-network"
  }),
      data = _useOrdersByUserBasic.data,
      loading = _useOrdersByUserBasic.loading,
      loadMore = _useOrdersByUserBasic.loadMore;

  var orderInProgress = {
    edges: data === null || data === void 0 ? void 0 : data.edges.filter(function (element) {
      return element.node.status.toLocaleUpperCase() !== (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.DELIVERED) && element.node.status.toLocaleUpperCase() !== (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.CANCELED) && element.node.status.toLocaleUpperCase() !== (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.UNDELIVERED) && element.node.status.toLocaleUpperCase() !== (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.UNFULFILLED) && element.node.status.toLocaleUpperCase() !== (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.TIMEOUT) && element.node.status.toLocaleUpperCase() !== (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.CANCELLED_BY_CUSTOMER) && element.node.status.toLocaleUpperCase() !== (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.CANCELLED_BY_SYSTEM);
    })
  };
  var orderFinished = {
    edges: data === null || data === void 0 ? void 0 : data.edges.filter(function (element) {
      return element.node.status.toLocaleUpperCase() === (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.DELIVERED) || element.node.status.toLocaleUpperCase() === (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.CANCELED) || element.node.status.toLocaleUpperCase() === (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.UNDELIVERED) || element.node.status.toLocaleUpperCase() === (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.UNFULFILLED) || element.node.status.toLocaleUpperCase() === (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.TIMEOUT) || element.node.status.toLocaleUpperCase() === (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.CANCELLED_BY_CUSTOMER) || element.node.status.toLocaleUpperCase() === (OrderStatus === null || OrderStatus === void 0 ? void 0 : OrderStatus.CANCELLED_BY_SYSTEM);
    })
  };
  useEffect(function () {
    if (!loading && (data === null || data === void 0 ? void 0 : data.edges) && (data === null || data === void 0 ? void 0 : data.edges.length) > 0) {
      var newOrders = data === null || data === void 0 ? void 0 : data.edges.map(function (order) {
        return order.node;
      });
      dispatch(addOrders({
        orders: newOrders
      }));
    }
  }, [data, loading]);
  useEffect(function () {
    localStorage.removeItem("order_token");
  }, []);
  useLayoutEffect(function () {
    var timer = setTimeout(function () {
      if (!!localStorage.getItem("status_page") && !!localStorage.getItem("scroll_order_list") && localStorage.getItem("status_page") === "true") {
        window.scrollTo(0, Number(localStorage.getItem("scroll_order_list")));
      }
    }, 0);
    return function () {
      clearTimeout(timer);
      localStorage.removeItem("status_page");
      localStorage.setItem("scroll_order_list", "".concat(scrollY));
    };
  }, []);
  return React.createElement(React.Fragment, null, loading && !data ? React.createElement(S.WrapperLoader, null, React.createElement(S.Footer, null, React.createElement(Footer, null)), React.createElement(Loader, null)) : React.createElement(React.Fragment, null, orderInProgress && (orderInProgress === null || orderInProgress === void 0 ? void 0 : orderInProgress.edges) && (orderInProgress === null || orderInProgress === void 0 ? void 0 : orderInProgress.edges.length) > 0 && React.createElement(React.Fragment, null, React.createElement(S.WrapperAll, null, React.createElement(S.Tile, null, React.createElement(FormattedMessage, {
    id: "@next.pages.OrdersHistory.OrdersHistory.2131873964",
    defaultMessage: "Orders in progress"
  })), React.createElement(S.TitleAmount, null, orderInProgress && (orderInProgress === null || orderInProgress === void 0 ? void 0 : orderInProgress.edges) !== undefined && (orderInProgress === null || orderInProgress === void 0 ? void 0 : orderInProgress.edges.length)), React.createElement(OrderTable, {
    data: orderInProgress,
    inProgressFlag: false
  }))), orderFinished && (orderFinished === null || orderFinished === void 0 ? void 0 : orderFinished.edges) && (orderFinished === null || orderFinished === void 0 ? void 0 : orderFinished.edges.length) > 0 && React.createElement(React.Fragment, null, React.createElement(S.WrapperAll, null, React.createElement(S.Footer, null, React.createElement(Footer, null)), React.createElement(S.Tile, null, React.createElement(FormattedMessage, {
    id: "@next.pages.OrdersHistory.OrdersHistory.116518960",
    defaultMessage: "Orders history"
  })), React.createElement(OrderTable, {
    data: orderFinished,
    endCursor: data === null || data === void 0 ? void 0 : (_data$pageInfo = data.pageInfo) === null || _data$pageInfo === void 0 ? void 0 : _data$pageInfo.endCursor,
    inProgressFlag: false,
    loading: loading,
    loadMore: loadMore,
    hasNextPage: data === null || data === void 0 ? void 0 : (_data$pageInfo2 = data.pageInfo) === null || _data$pageInfo2 === void 0 ? void 0 : _data$pageInfo2.hasNextPage
  }))), orderInProgress && (orderInProgress === null || orderInProgress === void 0 ? void 0 : orderInProgress.edges) && (orderInProgress === null || orderInProgress === void 0 ? void 0 : orderInProgress.edges.length) === 0 && orderFinished && (orderFinished === null || orderFinished === void 0 ? void 0 : orderFinished.edges) && (orderFinished === null || orderFinished === void 0 ? void 0 : orderFinished.edges.length) === 0 && React.createElement(React.Fragment, null, React.createElement(S.WrapperAll, null, React.createElement(S.Footer, null, React.createElement(Footer, null)), React.createElement(S.Tile, null, React.createElement(FormattedMessage, {
    id: "@next.pages.OrdersHistory.OrdersHistory.116518960",
    defaultMessage: "Orders history"
  })), React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.pages.OrdersHistory.OrdersHistory.3758580685",
    defaultMessage: "You haven't placed your first order yet!"
  })), React.createElement(S.Image, null, React.createElement(ReactSVG, {
    path: inciteBuy
  }))))));
};
OrdersHistory.displayName = "OrderHistory";