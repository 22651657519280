import { waNumber } from "../../constants";
var slugUrl = ":slug/:id/";
var slugUrlEditProduct = ":slug/:id/:subCategoryId/:lineId/";
var slugUrlProduct = ":slug/:id/:subCategoryId/";
export var baseUrl = "/";
export var searchUrl = "".concat(baseUrl, "search/");
export var categoryRedirectUrl = "".concat(baseUrl, "category/").concat(slugUrl, ":redirect");
export var categoryUrl = "".concat(baseUrl, "category/").concat(slugUrl);
export var categoriesUrl = "".concat(baseUrl, "category/");
export var subCategoryRedirectHomeUrl = "".concat(baseUrl, "subcategory/").concat(slugUrl, ":redirect/:redirectHome");
export var subCategoryRedirectUrl = "".concat(baseUrl, "subcategory/").concat(slugUrl, ":redirect");
export var subCategoryUrl = "".concat(baseUrl, "subcategory/").concat(slugUrl);
export var collectionUrl = "".concat(baseUrl, "collection/").concat(slugUrl);
export var productEditUrl = "".concat(baseUrl, "product/edit/").concat(slugUrlEditProduct);
export var productUrl = "".concat(baseUrl, "product/").concat(slugUrlProduct);
export var saleUrl = "".concat(baseUrl, "sale/");
export var saleCategoryUrl = "".concat(baseUrl, "sale/category/").concat(slugUrl);
export var saleCategoryRedirectUrl = "".concat(baseUrl, "sale/category/").concat(slugUrl, ":redirect");
export var cartTokenUrl = "".concat(baseUrl, "cart/:token/");
export var cartUrl = "".concat(baseUrl, "cart/");
export var checkoutLoginUrl = "".concat(baseUrl, "login/");
export var pageUrl = "".concat(baseUrl, "page/:slug/");
export var epTermsPageUrl = "".concat(baseUrl, "ep-terms-page/:userId/");
export var guestOrderDetailsUrl = "/order-history/:token/";
export var accountUrl = "".concat(baseUrl, "account/");
export var accountConfirmUrl = "".concat(baseUrl, "account-confirm/");
export var orderHistoryUrl = "".concat(baseUrl, "order-history/");
export var orderInProgressUrl = "".concat(baseUrl, "order-in-progress/");
export var addressBookUrl = "".concat(baseUrl, "address-book/");
export var passwordResetUrl = "".concat(baseUrl, "reset-password/");
export var checkoutUrl = "".concat(baseUrl, "checkout/*");
export var statusTokenUrl = "".concat(baseUrl, "status/:token/*");
export var statusPaymentUrl = "".concat(baseUrl, "status/payment/*");
export var statusUrl = "".concat(baseUrl, "status/:id/*");
export var statusPTokenUrl = "".concat(baseUrl, "pstatus/:token/*");
export var statusPUrl = "".concat(baseUrl, "pstatusp/:id/*");
export var orderFinalizedUrl = "".concat(baseUrl, "order-finalized/");
export var addressUrl = "".concat(baseUrl, "address/*");
export var neighborhoodsUrl = "".concat(baseUrl, "neighborhoods/"); // --------- Login from whatsapp ---------------
// This path must be the same as the path defined in the variable 'waLoginPath' on the file
// scr/index.tsx

export var waLogin = "".concat(baseUrl, "web-access/:userId/:accessCode/:tradeId/");
export var waLoginProfile = "".concat(baseUrl, "web-access-customer-profile/:userId/:accessCode/");
export var waLoginOrders = "".concat(baseUrl, "web-access-orders/:userId/:accessCode/");
export var waLoginOrdersInProgress = "".concat(baseUrl, "web-access-orders-in-progress/:userId/:accessCode/");
export var waLoginOrder = "".concat(baseUrl, "web-access/:userId/:accessCode/:tradeId/order/:orderToken"); // ------------ E-commerce ----------------

export var ecommerceUrl = "".concat(baseUrl, "web/:slug"); // ------------ EasyLink ----------------

export var easyLinkUrl = "".concat(baseUrl, "easylink/:slug"); // ------------ Delivery ----------------

export var deliveryUrl = "".concat(baseUrl, "deliveryman"); // ------- Expresiones Regulares -------

export var waLoginRegExp = new RegExp("".concat(baseUrl, "web-access/[0-9]+/[A-Za-z0-9_]+/[0-9]+/"));
export var waLoginProfileRegExp = new RegExp("".concat(baseUrl, "web-access-customer-profile/[0-9]+/[A-Za-z0-9_]+/"));
export var waLoginOrdersRegExp = new RegExp("".concat(baseUrl, "web-access-orders/[0-9]+/[A-Za-z0-9_]+/"));
export var waLoginOrdersInProgressRegExp = new RegExp("".concat(baseUrl, "web-access-orders-in-progress/[0-9]+/[A-Za-z0-9_]+/"));
export var waLoginOrderRegExp = new RegExp("".concat(baseUrl, "web-access/[0-9]+/[A-Za-z0-9_]+/[0-9]+/order/[A-Za-z0-9]+"));
export var ecommerceRegExp = new RegExp("".concat(baseUrl, "web/[A-Za-z0-9_]+")); // ---------------------------------------------

export var urlOpenWhatsapp = "https://api.whatsapp.com/send?phone=".concat(waNumber);