import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject25() {
  var data = _taggedTemplateLiteral(["\n  color: red;\n  font-size: 1rem;\n  text-align: center;\n  margin-top: 1rem;\n"]);

  _templateObject25 = function _templateObject25() {
    return data;
  };

  return data;
}

function _templateObject24() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n"]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  z-index: 99999;\n"]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n    width: 80%;\n    height: 45px;\n  "]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  width: 45%;\n  height: 50px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n  background: white;\n  color: black;\n  font-size: 1rem;\n  cursor: pointer;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n  &:first-of-type {\n    background: #27c763;\n    box-shadow: 0px 5px 12px 1px rgba(0, 0, 0, 0.3);\n    color: white;\n    margin-bottom: 10px;\n  }\n  ", "\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n    flex-direction: column;\n  "]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  flex-direction: row;\n  ", "\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  color: black;\n  font-weight: 700;\n  border: 1px dashed #27c763;\n  padding: 10px;\n  background: #27c7630d;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n  "]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  padding: 0.5rem 0 0.5rem 0;\n  margin: 0 auto;\n  font-size: 1rem;\n  text-align: center;\n  text-transform: lowercase;\n  color: grey;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n  ", "\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n  "]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  padding: 0.5rem 0 0.5rem 0;\n  margin: 0 auto;\n  margin-bottom: 1rem;\n  font-size: 1rem;\n  text-align: center;\n  text-transform: lowercase;\n  color: grey;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n  ", "\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 250px;\n  margin: 0 auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(0.8);\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n    padding: 0 0 0.5rem 0;\n    border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n    box-shadow: none;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  padding: 0.6rem;\n  margin-bottom: 1rem;\n  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n  border-bottom: none;\n  font-size: 1.5rem;\n  font-weight: ", ";\n  text-align: center;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  grid-area: footer;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  grid-area: aside;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  align-self: center;\n  font-size: 1rem;\n  text-transform: lowercase;\n  &::first-letter {\n    text-transform: uppercase;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 50% 50%;\n  position: absolute;\n  top: 0.4rem;\n  left: 1rem;\n  color: #f76268;\n  cursor: pointer;\n  font-size: 1rem;\n  text-transform: uppercase;\n  div {\n    height: 35px;\n    align-self: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  svg {\n    transform: scale(0.4);\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: grid;\n  align-items: center;\n  grid-template-rows: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n    display: grid;\n    grid-template-rows: 12% 88%;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: content;\n  height: auto;\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: tile;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    grid-template-areas:\n      \"content content\"\n      \"content content\"\n      \"content content\"\n      \"aside aside\";\n    grid-template-columns: 100%;\n    grid-template-rows: 0.1fr 0.2fr 0.5fr 0.5fr;\n    }\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-areas:\n    \"tile tile\"\n    \"content aside\"\n    \"footer footer\";\n  position: relative;\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled, media } from "@styles";
export var Wrapper = styled.div(_templateObject(), media.xMediumScreen(_templateObject2()));
export var Tile = styled.div(_templateObject3());
export var Content = styled.div(_templateObject4(), media.xMediumScreen(_templateObject5()));
export var ContentContainer = styled.div(_templateObject6(), function (props) {
  return props.paymentButtons ? "50% 20% 20%" : "60% 40%";
});
export var BackTitle = styled.div(_templateObject7());
export var BackTitleParragraph = styled.p(_templateObject8());
export var Aside = styled.div(_templateObject9());
export var Footer = styled.div(_templateObject10());
export var Title = styled.h3(_templateObject11(), function (props) {
  return props.theme.typography.boldFontWeight;
}, media.xMediumScreen(_templateObject12()));
export var Images = styled.div(_templateObject13());
export var Parragraph = styled.p(_templateObject14(), media.xMediumScreen(_templateObject15()));
export var ParragraphLetter = styled.p(_templateObject16(), media.xMediumScreen(_templateObject17()));
export var Span = styled.span(_templateObject18());
export var Buttons = styled.div(_templateObject19(), media.xMediumScreen(_templateObject20()));
export var Button = styled.button(_templateObject21(), media.xMediumScreen(_templateObject22()));
export var Loader = styled.div(_templateObject23());
export var Errors = styled.div(_templateObject24());
export var Error = styled.div(_templateObject25());