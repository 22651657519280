import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/atoms";
import { baseUrl } from "@temp/app/routes";
import { CheckoutErrorsEnum } from "@globalTypes/globalTypes";

var closeWhite = require("../../images/closewhite.svg"); // const greaterThanEqual = require("../../images/greaterThanEqual.svg");


export var AlertCheckoutErrors = function AlertCheckoutErrors(_ref) {
  var completeCheckoutAgain = _ref.completeCheckoutAgain,
      hideModal = _ref.hideModal,
      updatePaymentMethod = _ref.updatePaymentMethod,
      errors = _ref.errors;
  var navigate = useNavigate();
  var commonErrors = Object.fromEntries(errors === null || errors === void 0 ? void 0 : errors.filter(function (e) {
    return (e === null || e === void 0 ? void 0 : e.code) === CheckoutErrorsEnum.INSUFFICIENT_STOCK || (e === null || e === void 0 ? void 0 : e.code) === CheckoutErrorsEnum.SHIPPING_METHOD_NOT_SET || (e === null || e === void 0 ? void 0 : e.code) === CheckoutErrorsEnum.NOT_SUPPORTED_GATEWAY || (e === null || e === void 0 ? void 0 : e.code) === CheckoutErrorsEnum.PAYMENT_ONLINE_ERROR || (e === null || e === void 0 ? void 0 : e.code) === CheckoutErrorsEnum.CONNECTIONERROR;
  }).map(function (e) {
    return [e.code, e.code];
  }));

  var closeModal = function closeModal() {
    if (commonErrors === null || commonErrors === void 0 ? void 0 : commonErrors.NOT_SUPPORTED_GATEWAY) {
      if (updatePaymentMethod) {
        updatePaymentMethod();
      }

      if (!location.pathname.includes("checkout/payment")) {
        navigate("/checkout/payment");
      }
    }

    hideModal();
  };

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "alertCheckoutErrors",
    onClick: closeModal
  }), React.createElement("div", {
    className: "alertCheckoutErrors__data"
  }, React.createElement("div", {
    className: "alertCheckoutErrors__data__close"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutErrors.index.709932324",
    defaultMessage: "Alert"
  })), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "alertCheckoutErrors__data__content alertCheckoutErrors__data__content--first alertCheckoutErrors__data__content--last"
  }, React.createElement("ul", null, errors.map(function (element, index) {
    return element.code === CheckoutErrorsEnum.INSUFFICIENT_STOCK ? React.createElement("li", {
      key: index
    }, JSON.parse(element.message).message) : element.code === CheckoutErrorsEnum.PAYMENT_ONLINE_ERROR || element.code === CheckoutErrorsEnum.CONNECTIONERROR ? React.createElement("li", {
      key: index
    }, React.createElement(FormattedMessage, {
      id: "components.AlertCheckoutErrors.index.237248926",
      defaultMessage: "An error occurred with the payment gateway"
    })) : React.createElement("li", {
      key: index
    }, element.message);
  }))), React.createElement("div", {
    className: "alertCheckoutErrors__data__footer"
  }, (commonErrors === null || commonErrors === void 0 ? void 0 : commonErrors.PAYMENT_ONLINE_ERROR) || (commonErrors === null || commonErrors === void 0 ? void 0 : commonErrors.CONNECTIONERROR) ? React.createElement("div", {
    className: "alertCheckoutErrors__data__footer__onlineError"
  }, React.createElement(Button, {
    type: "button",
    color: "primary",
    testingContext: "",
    onClick: function onClick() {
      if (completeCheckoutAgain) {
        completeCheckoutAgain();
      }

      hideModal();
    }
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutErrors.index.3294346597",
    defaultMessage: "Try again"
  })), React.createElement(Button, {
    type: "button",
    color: "primary",
    testingContext: "",
    onClick: function onClick() {
      if (!location.pathname.includes("checkout/payment")) {
        navigate("/checkout/payment");
      }

      hideModal();
    }
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutErrors.index.826811448",
    defaultMessage: "Change payment method"
  }))) : (commonErrors === null || commonErrors === void 0 ? void 0 : commonErrors.NOT_SUPPORTED_GATEWAY) ? React.createElement(Button, {
    type: "button",
    color: "primary",
    testingContext: "",
    onClick: closeModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutErrors.index.3577703060",
    defaultMessage: "Ok"
  })) : React.createElement(Button, {
    type: "button",
    color: "primary",
    testingContext: "",
    onClick: function onClick() {
      if ((commonErrors === null || commonErrors === void 0 ? void 0 : commonErrors.SHIPPING_METHOD_NOT_SET) && !location.pathname.includes("checkout/address")) {
        navigate("/checkout/address");
      } else if (commonErrors === null || commonErrors === void 0 ? void 0 : commonErrors.SHIPPING_METHOD_NOT_SET) {
        navigate("/checkout");
        navigate("/checkout/address");
      }

      hideModal();
    }
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutErrors.index.321321683",
    defaultMessage: "Acept"
  })), (commonErrors === null || commonErrors === void 0 ? void 0 : commonErrors.INSUFFICIENT_STOCK) && React.createElement(Button, {
    type: "button",
    color: "primary",
    className: "alertCheckoutErrors__data__footer__modify",
    testingContext: "",
    onClick: function onClick() {
      localStorage.setItem("checkout-modify", "true");
      navigate(baseUrl);
    }
  }, React.createElement(FormattedMessage, {
    id: "components.AlertCheckoutErrors.index.2249881922",
    defaultMessage: "Modify"
  })))));
};
export default AlertCheckoutErrors;