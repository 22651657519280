import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Chip, ErrorMessage, Input } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import { useForm } from "@temp/hooks";
import * as S from "./styles";
export var DiscountForm = function DiscountForm(_ref) {
  var handleSubmit = _ref.handleSubmit,
      setInputPromoValue = _ref.setInputPromoValue,
      discount = _ref.discount,
      errors = _ref.errors,
      formId = _ref.formId,
      formRef = _ref.formRef,
      showSetPromo = _ref.showSetPromo,
      inputPromoValue = _ref.inputPromoValue,
      loadingPromoCode = _ref.loadingPromoCode;
  var promoCode = discount && discount.promoCode;
  var inputRef = React.useRef(null);

  var _React$useState = React.useState(promoCode),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      tempPromoCode = _React$useState2[0],
      setTempPromoCode = _React$useState2[1];

  var intl = useIntl();
  var initialValues = {
    errors: errors,
    inputCode: inputPromoValue
  };

  var _useForm = useForm(initialValues),
      inputCode = _useForm.inputCode,
      handleChangeInput = _useForm.handleChangeInput;

  var handleApplyBtnClick = function handleApplyBtnClick(newInputCode) {
    setTempPromoCode(newInputCode);

    if (setInputPromoValue) {
      setInputPromoValue("");
    }

    if (newInputCode && newInputCode.length > 0) {
      _handleSubmit({
        tempPromoCode: newInputCode
      }, null, true);
    }
  };

  var handleRemoveBtnClick = function handleRemoveBtnClick(newInputCode) {
    setTempPromoCode(undefined);

    if (setInputPromoValue) {
      setInputPromoValue(newInputCode);
    }

    _handleSubmit({
      tempPromoCode: newInputCode
    }, null, true);
  };

  var _handleSubmit = function _handleSubmit(values, submitting, notSubmitForm) {
    if (handleSubmit) {
      handleSubmit({
        promoCode: values.tempPromoCode
      }, notSubmitForm);
    }

    if (submitting) {
      submitting.setSubmitting(false);
    }
  };

  var handleSubmitForm = function handleSubmitForm(e, newInputCode) {
    e.preventDefault();

    _handleSubmit({
      tempPromoCode: newInputCode
    }, null, true);
  };

  useEffect(function () {
    if (promoCode) {
      var _inputRef$current;

      inputRef === null || inputRef === void 0 ? void 0 : (_inputRef$current = inputRef.current) === null || _inputRef$current === void 0 ? void 0 : _inputRef$current.blur();
    } else {
      var _inputRef$current2;

      inputRef === null || inputRef === void 0 ? void 0 : (_inputRef$current2 = inputRef.current) === null || _inputRef$current2 === void 0 ? void 0 : _inputRef$current2.focus();
    }
  }, [promoCode]);
  return React.createElement(S.DiscountForm, {
    id: formId,
    ref: formRef,
    onSubmit: function onSubmit(e) {
      return handleSubmitForm(e, inputCode);
    },
    "data-test": "discountForm"
  }, React.createElement(S.Input, null, React.createElement(S.InputWithButton, null, React.createElement(S.InputWrapper, null, React.createElement(Input, {
    inputRef: inputRef,
    name: "inputCode",
    value: inputCode,
    label: intl.formatMessage(_defineProperty({
      defaultMessage: "Enter your code",
      "id": "@next.components.organisms.DiscountForm.DiscountForm.2056449444"
    }, "id", "@next.components.organisms.DiscountForm.DiscountForm.2056449444")),
    autoFocus: !promoCode,
    onChange: handleChangeInput,
    placeholder: intl.formatMessage(_defineProperty({
      defaultMessage: "Code",
      "id": "@next.components.organisms.DiscountForm.DiscountForm.78726751"
    }, "id", "@next.components.organisms.DiscountForm.DiscountForm.78726751"))
  })), React.createElement(S.ButtonWrapper, {
    onClick: function onClick() {
      handleApplyBtnClick(inputCode.toLowerCase());
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.DiscountForm.DiscountForm.3476123",
    defaultMessage: "Apply"
  }))), !showSetPromo && React.createElement(ErrorMessage, {
    errors: errors
  })), tempPromoCode && !loadingPromoCode && React.createElement(React.Fragment, null, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.promoCode), ":"), React.createElement(S.ChipsWrapper, null, React.createElement(Chip, {
    onClick: function onClick() {
      return handleRemoveBtnClick(inputCode);
    }
  }, React.createElement("span", {
    "data-test": "promoCodeChip"
  }, tempPromoCode)))));
};