import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { useNavigate } from "react-router-dom";
import { Money } from "@components/containers";
import { CachedImage } from "@components/molecules";
import { translatePaymentStatus } from "@temp/intl";
import { GatewayTypesEnum } from "@globalTypes/globalTypes";
import { maybe } from "../../../../core/utils";
import * as S from "./styles";
import dateImg from "../../../../images/date.svg";
import deliveryImg from "../../../../images/delivery.svg";
import shippingImg from "../../../../images/checkout/checkoutHome.svg";
import cartImg from "../../../../images/cart.svg";
import cashImg from "../../../../images/checkout/checkoutCash.svg";
import dataphoneImg from "../../../../images/dataphoneImg.svg";
import nequiImg from "../../../../images/logoNequi.svg";
import observationsImg from "../../../../images/checkout/checkoutObservations.svg";
import totalImg from "../../../../images/checkout/checkoutMoney.svg";
import telephoneImg from "../../../../images/callToDeliveryMan.svg";
/**
 * Order info
 */

var StatusData = function StatusData(_ref) {
  var _data$shippingAddress, _data$shippingAddress2, _data$shippingAddress3, _data$shippingAddress4, _data$lastFulfillment, _data$lastFulfillment2, _data$lastFulfillment3, _data$lastFulfillment4, _data$lastFulfillment5, _data$lastFulfillment12, _data$lastFulfillment13, _data$lastFulfillment14, _data$lastFulfillment15;

  var deliveryPhone = _ref.deliveryPhone,
      data = _ref.data,
      deliveryData = _ref.deliveryData;
  var intl = useIntl();
  var navigate = useNavigate();
  var dateRender = new Date(data.created).toLocaleString("en-GB", {
    hour12: true,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });
  var address2 = data.shippingAddress.streetAddress2 !== undefined && data.shippingAddress.streetAddress2 !== "" ? ", ".concat(data.shippingAddress.streetAddress2) : "";
  var address = "".concat(data === null || data === void 0 ? void 0 : (_data$shippingAddress = data.shippingAddress) === null || _data$shippingAddress === void 0 ? void 0 : _data$shippingAddress.streetAddress1, " ").concat(address2);
  var neighborhood = "".concat(data === null || data === void 0 ? void 0 : (_data$shippingAddress2 = data.shippingAddress) === null || _data$shippingAddress2 === void 0 ? void 0 : (_data$shippingAddress3 = _data$shippingAddress2.neighborhood) === null || _data$shippingAddress3 === void 0 ? void 0 : _data$shippingAddress3.name);
  var observations = "".concat(data === null || data === void 0 ? void 0 : (_data$shippingAddress4 = data.shippingAddress) === null || _data$shippingAddress4 === void 0 ? void 0 : _data$shippingAddress4.lastName);
  var amountProducts = "".concat(data === null || data === void 0 ? void 0 : data.amountOfProducts);

  var goToOrderHistory = function goToOrderHistory() {
    navigate("/order-history/".concat(data.token));
  };

  var currencyLocal = data.total.net.currency;
  var conversorValue = currencyLocal === "COP" ? "es-CO" : undefined;
  var maximumFractionsDigitsValue = currencyLocal === "COP" ? 0 : 2;
  var scheduledDate;
  var dateScheduled;
  var timeScheduled;

  if (data.scheduledAt) {
    scheduledDate = new Date(data.scheduledAt);
    dateScheduled = scheduledDate.toLocaleDateString();
    timeScheduled = scheduledDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true
    });
    timeScheduled = timeScheduled.replace(/\./g, "").replace(/\s/g, "").replace(/(p|a)/i, " $1");
  }

  var moneyValue = "";

  if (data.paymentGateway === "mirumee.payments.cash" && data.paymentToken !== "0" && !isNaN(+data.paymentToken)) {
    moneyValue = Number(data.paymentToken).toLocaleString(conversorValue, {
      currency: currencyLocal,
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionsDigitsValue
    });
  }

  return React.createElement(S.Wrapper, null, deliveryData && ((_data$lastFulfillment = data.lastFulfillment) === null || _data$lastFulfillment === void 0 ? void 0 : _data$lastFulfillment.deliveryman) && React.createElement(React.Fragment, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.505561860",
    defaultMessage: "Delivery man"
  })), React.createElement(S.Delivery, null, React.createElement(S.DeliveryData, null, React.createElement(S.DeliveryImgData, null, ((_data$lastFulfillment2 = data.lastFulfillment) === null || _data$lastFulfillment2 === void 0 ? void 0 : (_data$lastFulfillment3 = _data$lastFulfillment2.deliveryman) === null || _data$lastFulfillment3 === void 0 ? void 0 : _data$lastFulfillment3.photo) && ((_data$lastFulfillment4 = data.lastFulfillment) === null || _data$lastFulfillment4 === void 0 ? void 0 : (_data$lastFulfillment5 = _data$lastFulfillment4.deliveryman) === null || _data$lastFulfillment5 === void 0 ? void 0 : _data$lastFulfillment5.photo) !== null ? React.createElement(CachedImage, {
    url: maybe(function () {
      var _data$lastFulfillment6, _data$lastFulfillment7;

      return (_data$lastFulfillment6 = data.lastFulfillment) === null || _data$lastFulfillment6 === void 0 ? void 0 : (_data$lastFulfillment7 = _data$lastFulfillment6.deliveryman) === null || _data$lastFulfillment7 === void 0 ? void 0 : _data$lastFulfillment7.photo.url;
    }),
    alt: maybe(function () {
      var _data$lastFulfillment8, _data$lastFulfillment9, _data$lastFulfillment10, _data$lastFulfillment11;

      return ((_data$lastFulfillment8 = data.lastFulfillment) === null || _data$lastFulfillment8 === void 0 ? void 0 : (_data$lastFulfillment9 = _data$lastFulfillment8.deliveryman) === null || _data$lastFulfillment9 === void 0 ? void 0 : _data$lastFulfillment9.photo.alt) ? (_data$lastFulfillment10 = data.lastFulfillment) === null || _data$lastFulfillment10 === void 0 ? void 0 : (_data$lastFulfillment11 = _data$lastFulfillment10.deliveryman) === null || _data$lastFulfillment11 === void 0 ? void 0 : _data$lastFulfillment11.photo.alt : "";
    }, "")
  }, React.createElement(React.Fragment, null)) : React.createElement(ReactSVG, {
    path: deliveryImg
  }))), ((_data$lastFulfillment12 = data.lastFulfillment) === null || _data$lastFulfillment12 === void 0 ? void 0 : (_data$lastFulfillment13 = _data$lastFulfillment12.deliveryman) === null || _data$lastFulfillment13 === void 0 ? void 0 : _data$lastFulfillment13.firstName) && React.createElement(S.DeliveryNameData, null, " ", data.lastFulfillment.deliveryman.firstName, " ", data.lastFulfillment.deliveryman.lastName && data.lastFulfillment.deliveryman.lastName), deliveryPhone && ((_data$lastFulfillment14 = data.lastFulfillment) === null || _data$lastFulfillment14 === void 0 ? void 0 : (_data$lastFulfillment15 = _data$lastFulfillment14.deliveryman) === null || _data$lastFulfillment15 === void 0 ? void 0 : _data$lastFulfillment15.phoneNumber) ? React.createElement(Media, {
    maxWidth: xMediumScreen
  }, function (matches) {
    var _data$lastFulfillment16, _data$lastFulfillment17, _data$lastFulfillment18, _data$lastFulfillment19;

    return matches ? React.createElement("a", {
      href: "tel:".concat((_data$lastFulfillment16 = data.lastFulfillment) === null || _data$lastFulfillment16 === void 0 ? void 0 : (_data$lastFulfillment17 = _data$lastFulfillment16.deliveryman) === null || _data$lastFulfillment17 === void 0 ? void 0 : _data$lastFulfillment17.phoneNumber)
    }, React.createElement(S.DeliveryImage, null, React.createElement(ReactSVG, {
      path: telephoneImg
    }))) : React.createElement(S.DeliveryNumber, null, (_data$lastFulfillment18 = data.lastFulfillment) === null || _data$lastFulfillment18 === void 0 ? void 0 : (_data$lastFulfillment19 = _data$lastFulfillment18.deliveryman) === null || _data$lastFulfillment19 === void 0 ? void 0 : _data$lastFulfillment19.phoneNumber);
  }) : React.createElement(React.Fragment, null))), React.createElement(S.TitleMyOrder, null, React.createElement(S.TitleMyOrderTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.2258231803",
    defaultMessage: "My order"
  })), React.createElement(S.TitleMyOrderNumber, null, "# ", data.number)), React.createElement(S.Order, null, React.createElement(S.OrderImage, null, React.createElement(ReactSVG, {
    path: cartImg
  })), React.createElement(S.OrderParragraphData, null, React.createElement(S.OrderBoldData, null, " ", amountProducts, " "), React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.2968663655",
    defaultMessage: "Products"
  })), React.createElement(S.OrderButton, {
    onClick: goToOrderHistory
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.3796752745",
    defaultMessage: "see detail"
  }))), React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.2255127890",
    defaultMessage: "order date"
  })), React.createElement(S.Date, null, React.createElement(S.DateImg, null, React.createElement(ReactSVG, {
    path: dateImg
  })), React.createElement(S.DateDate, null, " ", dateRender, " ")), data.scheduledAt && React.createElement(React.Fragment, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.3471638941",
    defaultMessage: "scheduled date"
  })), React.createElement(S.Date, null, React.createElement(S.DateImg, null, React.createElement(ReactSVG, {
    path: dateImg
  })), React.createElement(S.DateDate, null, " ", dateScheduled, " "), React.createElement(S.DateTime, null, " ", timeScheduled, " "))), React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.2758581442",
    defaultMessage: "Shipping Address"
  })), React.createElement(S.Shipping, null, React.createElement(S.ShippingImage, null, React.createElement(ReactSVG, {
    path: shippingImg
  })), React.createElement(S.ShippingData, null, React.createElement(S.ShippingBoldData, null), React.createElement(S.ShippingParragraphData, null, React.createElement(S.ShippingBoldData, null, " ", React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.359810770",
    defaultMessage: "Address"
  }), ":"), " ", address), React.createElement(S.ShippingParragraphData, null, React.createElement(S.ShippingBoldData, null, " ", React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.1024405385",
    defaultMessage: "Neighborhood"
  }), ":"), " ", neighborhood), observations !== null && observations !== "" && observations !== "null" && React.createElement(S.ShippingParragraphObservationsData, null, React.createElement(S.ShippingBoldData, null, " ", React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.722592990",
    defaultMessage: "Observations"
  }), ":"), " ", observations))), React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.348821000",
    defaultMessage: "Payment Method"
  })), React.createElement(S.Payment, null, data.paymentGateway === GatewayTypesEnum.CASH && React.createElement(React.Fragment, null, React.createElement(S.PaymentContainer, null, React.createElement(S.PaymentImage, null, React.createElement(ReactSVG, {
    path: cashImg
  })), React.createElement(S.PaymentData, null, React.createElement(S.PaymentParragraphCashData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.1923088536",
    defaultMessage: "Cash"
  })), moneyValue !== "" ? React.createElement(S.PaymentParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.498277231",
    defaultMessage: "Return for"
  }), " ", ": ", " ", React.createElement(S.PaymentBoldData, null, " ", moneyValue, " ")) : React.createElement(S.PaymentParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.648924719",
    defaultMessage: "The establishment does not know how much you are going to pay"
  })))), React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.3916362286",
    defaultMessage: "Payment status"
  }), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, translatePaymentStatus(data.paymentStatus, intl)))), data.paymentGateway === GatewayTypesEnum.DATAPHONE && React.createElement(React.Fragment, null, React.createElement(S.PaymentContainer, null, React.createElement(S.PaymentDataphoneImage, null, React.createElement(ReactSVG, {
    path: dataphoneImg
  })), React.createElement(S.PaymentData, null, React.createElement(S.PaymentParragraphCashData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.1475423084",
    defaultMessage: "Card (Dataphone)"
  })), React.createElement(S.PaymentParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.1050498566",
    defaultMessage: "The establishment will have its own dataphone"
  })))), React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.3916362286",
    defaultMessage: "Payment status"
  }), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, translatePaymentStatus(data.paymentStatus, intl)))), data.paymentGateway === GatewayTypesEnum.NEQUI && React.createElement(React.Fragment, null, React.createElement(S.PaymentContainer, null, React.createElement(S.PaymentDataphoneImage, null, React.createElement(ReactSVG, {
    path: nequiImg
  })), React.createElement(S.PaymentData, null, React.createElement(S.PaymentParragraphCashData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.2137966381",
    defaultMessage: "Nequi"
  })), React.createElement(S.PaymentParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.2442821705",
    defaultMessage: "The establishment will send you the payment instructions to your WhatsApp"
  })))), React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.3916362286",
    defaultMessage: "Payment status"
  }), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, translatePaymentStatus(data.paymentStatus, intl)))), data.paymentGateway === GatewayTypesEnum.ONLINE_CREDIBANCO && React.createElement(React.Fragment, null, React.createElement(S.PaymentContainer, null, React.createElement(S.PaymentDataphoneImage, null, React.createElement(ReactSVG, {
    path: nequiImg
  })), React.createElement(S.PaymentData, null, React.createElement(S.PaymentParragraphCashData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.213793202",
    defaultMessage: "Online payment"
  })), React.createElement(S.PaymentParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.4032039836",
    defaultMessage: "Paid through credibanco"
  })))), React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.3916362286",
    defaultMessage: "Payment status"
  }), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, translatePaymentStatus(data.paymentStatus, intl))))), React.createElement(Media, {
    maxWidth: xMediumScreen
  }, data.checkoutObservation && React.createElement(React.Fragment, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.3086091618",
    defaultMessage: "order observations"
  })), React.createElement(S.CheckoutObservation, null, React.createElement(S.CheckoutObservationImage, null, React.createElement(ReactSVG, {
    path: observationsImg
  })), React.createElement(S.CheckoutObservationData, null, data.checkoutObservation))), React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.2303681303",
    defaultMessage: "order value"
  })), React.createElement(S.Total, null, React.createElement(S.TotalImage, null, React.createElement(ReactSVG, {
    path: totalImg
  })), React.createElement(S.TotalData, null, React.createElement(S.SubTotalData, null, React.createElement(S.SubTotalParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.3123839650",
    defaultMessage: "subtotal"
  })), React.createElement(S.SubTotalSpanData, null, React.createElement(Money, {
    money: data.subtotal.net
  }))), data.shippingPrice !== undefined && data.shippingPrice !== null && data.shippingPrice !== "null" && React.createElement(S.ShippingTotalData, null, React.createElement(S.ShippingTotalParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.2278129743",
    defaultMessage: "shipping"
  })), React.createElement(S.ShippingTotalSpanData, null, React.createElement(Money, {
    money: data.shippingPrice.net
  }))), data.discount !== undefined && data.discount !== null && data.discount !== "null" && data.discount.amount > 0 && React.createElement(S.ShippingTotalData, null, React.createElement(S.ShippingTotalParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.293722515",
    defaultMessage: "discount"
  })), React.createElement(S.ShippingTotalSpanData, null, " -", React.createElement(Money, {
    money: data.discount
  }))), React.createElement(S.TotalTotalData, null, React.createElement(S.TotalTotalParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusData.StatusData.1214258741",
    defaultMessage: "total"
  })), React.createElement(S.TotalTotalSpanData, null, React.createElement(Money, {
    money: data.total.net
  })))))));
};

export { StatusData };