import React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { StatusData, StatusDataFooter } from "@components/molecules";
import { statusMessages } from "@temp/intl";
import * as S from "./styles";

var arrivedImg = require("../../../../images/arrived.gif");

var goBackImg = require("../../../../images/arrowBack.svg");

/**
 * arrived status structure
 */
var StatusArrived = function StatusArrived(_ref) {
  var _data$lastFulfillment, _data$lastFulfillment2;

  var data = _ref.data,
      goBack = _ref.goBack;
  return React.createElement(S.Wrapper, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: goBack
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.StatusArrived.StatusArrived.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNameArrived)))), React.createElement(S.Content, null, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(S.Title, null, React.createElement(FormattedMessage, statusMessages.stepNameArrived))), React.createElement(S.ContentContainer, null, React.createElement(S.Gif, null, React.createElement("img", {
    alt: "cargando",
    src: arrivedImg
  })), React.createElement(S.Parragraph, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, statusMessages.stepNameArrivedWaiting)), (data === null || data === void 0 ? void 0 : (_data$lastFulfillment = data.lastFulfillment) === null || _data$lastFulfillment === void 0 ? void 0 : (_data$lastFulfillment2 = _data$lastFulfillment.deliveryman) === null || _data$lastFulfillment2 === void 0 ? void 0 : _data$lastFulfillment2.phoneNumber) && React.createElement(Media, {
    minWidth: xMediumScreen
  }, function (matches) {
    var _data$lastFulfillment3, _data$lastFulfillment4;

    return matches && React.createElement(S.Delivery, null, React.createElement(S.DeliveryTile, null, React.createElement(FormattedMessage, {
      id: "@next.components.organisms.StatusArrived.StatusArrived.546197434",
      defaultMessage: "delivery man number"
    })), React.createElement(S.DeliveryNumber, null, data === null || data === void 0 ? void 0 : (_data$lastFulfillment3 = data.lastFulfillment) === null || _data$lastFulfillment3 === void 0 ? void 0 : (_data$lastFulfillment4 = _data$lastFulfillment3.deliveryman) === null || _data$lastFulfillment4 === void 0 ? void 0 : _data$lastFulfillment4.phoneNumber));
  }))), React.createElement(S.Aside, null, React.createElement(StatusData, {
    deliveryPhone: true,
    data: data,
    deliveryData: true
  })), React.createElement(S.Footer, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(StatusDataFooter, {
    data: data
  }))));
};

export { StatusArrived };