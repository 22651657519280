import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSVG from "react-svg";
import { useNavigate } from "react-router-dom";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { Money } from "@components/containers";
import { AlertSeeVoucher } from "@temp/components";
import { commonMessages, statusMessages, translatePaymentStatus } from "@temp/intl";
import { GatewayTypesEnum } from "@globalTypes/globalTypes";
import * as S from "./styles";
import customerImg from "../../../../images/delivery.svg";
import shippingImg from "../../../../images/checkout/checkoutHome.svg";
import cartImg from "../../../../images/cart.svg";
import cashImg from "../../../../images/checkout/checkoutCash.svg";
import dataphoneImg from "../../../../images/dataphoneImg.svg";
import dateImg from "../../../../images/date.svg";
import deliveredImg from "../../../../images/delivered.svg";
import goBackImg from "../../../../images/arrowBack.svg";
import nequiImg from "../../../../images/logoNequi.svg";
import observationsImg from "../../../../images/checkout/checkoutObservations.svg";
import totalImg from "../../../../images/checkout/checkoutMoney.svg";
import telephoneImg from "../../../../images/delivery/telephone.svg";
import undeliveredImg from "../../../../images/undelivered.svg";

var arrivedImg = require("../../../../images/arrived.gif");

var assignedImg = require("../../../../images/moto.gif");

var canceledFulfillmentImg = require("../../../../images/changeDelivery.gif");

var orderNoApprovedImg = require("../../../../images/noApproved.svg");
/**
 * Order info
 */


var cardOptions = [{
  id: "debit",
  name: React.createElement(FormattedMessage, commonMessages.debit)
}, {
  id: "credit",
  name: React.createElement(FormattedMessage, commonMessages.credit)
}];

var StatusDataDelivery = function StatusDataDelivery(_ref) {
  var _data$lastFulfillment, _data$lastFulfillment2, _data$lastFulfillment3, _data$shippingAddress, _data$shippingAddress2, _data$shippingAddress3, _data$shippingAddress4, _data$shippingAddress5, _data$shippingAddress6, _data$trade, _data$trade$obligator, _data$total, _data$total$net, _data$lastFulfillment4, _data$lastFulfillment5, _data$lastFulfillment6, _data$lastFulfillment7, _data$user, _data$user2, _data$paymentGateway, _data$paymentGateway2, _cardOptions$find, _fieldPaymentConfirma, _data$paymentGateway3, _fieldPaymentConfirma2, _data$paymentGateway4, _data$checkoutObserva, _data$total3, _data$status, _data$status2, _data$status3, _data$status4;

  var data = _ref.data;
  var intl = useIntl();
  var navigate = useNavigate();
  var lastFulfillmentSentAt = data === null || data === void 0 ? void 0 : (_data$lastFulfillment = data.lastFulfillment) === null || _data$lastFulfillment === void 0 ? void 0 : _data$lastFulfillment.sentAt;
  var lastFulfillmentArrivedAt = data === null || data === void 0 ? void 0 : (_data$lastFulfillment2 = data.lastFulfillment) === null || _data$lastFulfillment2 === void 0 ? void 0 : _data$lastFulfillment2.arrivedAt;
  var lastFulfillmentFinishedAt = data === null || data === void 0 ? void 0 : (_data$lastFulfillment3 = data.lastFulfillment) === null || _data$lastFulfillment3 === void 0 ? void 0 : _data$lastFulfillment3.finishedAt;
  var address2 = (data === null || data === void 0 ? void 0 : (_data$shippingAddress = data.shippingAddress) === null || _data$shippingAddress === void 0 ? void 0 : _data$shippingAddress.streetAddress2) !== undefined && (data === null || data === void 0 ? void 0 : (_data$shippingAddress2 = data.shippingAddress) === null || _data$shippingAddress2 === void 0 ? void 0 : _data$shippingAddress2.streetAddress2) !== "" ? ", ".concat(data === null || data === void 0 ? void 0 : (_data$shippingAddress3 = data.shippingAddress) === null || _data$shippingAddress3 === void 0 ? void 0 : _data$shippingAddress3.streetAddress2) : "";
  var address = "".concat(data === null || data === void 0 ? void 0 : (_data$shippingAddress4 = data.shippingAddress) === null || _data$shippingAddress4 === void 0 ? void 0 : _data$shippingAddress4.streetAddress1, " ").concat(address2);
  var neighborhood = "".concat(data === null || data === void 0 ? void 0 : (_data$shippingAddress5 = data.shippingAddress) === null || _data$shippingAddress5 === void 0 ? void 0 : _data$shippingAddress5.neighborhood.name);
  var observations = "".concat(data === null || data === void 0 ? void 0 : (_data$shippingAddress6 = data.shippingAddress) === null || _data$shippingAddress6 === void 0 ? void 0 : _data$shippingAddress6.lastName);
  var amountProducts = "".concat(data === null || data === void 0 ? void 0 : data.amountOfProducts);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showSeeVoucher = _React$useState2[0],
      setShowSeeVoucher = _React$useState2[1];

  var goToOrderHistory = function goToOrderHistory() {
    navigate("/delivery/order-detail/".concat(data === null || data === void 0 ? void 0 : data.token));
  };

  var fieldPaymentConfirmationCode = data === null || data === void 0 ? void 0 : (_data$trade = data.trade) === null || _data$trade === void 0 ? void 0 : (_data$trade$obligator = _data$trade.obligatoryField) === null || _data$trade$obligator === void 0 ? void 0 : _data$trade$obligator.filter(function (field) {
    var _field$modelName;

    return (field === null || field === void 0 ? void 0 : field.fieldName) && (field === null || field === void 0 ? void 0 : field.fieldName) !== "" && (field === null || field === void 0 ? void 0 : field.fieldName.toLowerCase()) === "payment_confirmation_code" && (field === null || field === void 0 ? void 0 : field.role.toLowerCase()) === "delivery_man" && (field === null || field === void 0 ? void 0 : (_field$modelName = field.modelName) === null || _field$modelName === void 0 ? void 0 : _field$modelName.toLowerCase()) === "payment";
  });
  var currencyLocal = data === null || data === void 0 ? void 0 : (_data$total = data.total) === null || _data$total === void 0 ? void 0 : (_data$total$net = _data$total.net) === null || _data$total$net === void 0 ? void 0 : _data$total$net.currency;
  var conversorValue = currencyLocal === "COP" ? "es-CO" : undefined;
  var maximumFractionsDigitsValue = currencyLocal === "COP" ? 0 : 2;
  var moneyValue = "";
  var returnsValue = "";

  if ((data === null || data === void 0 ? void 0 : data.paymentGateway) === GatewayTypesEnum.CASH && (data === null || data === void 0 ? void 0 : data.paymentToken) !== "0" && (data === null || data === void 0 ? void 0 : data.paymentToken) !== null && (data === null || data === void 0 ? void 0 : data.paymentToken) !== undefined && !isNaN(+(data === null || data === void 0 ? void 0 : data.paymentToken))) {
    var _data$total2;

    var returnsResult = (data === null || data === void 0 ? void 0 : data.paymentToken) - (data === null || data === void 0 ? void 0 : (_data$total2 = data.total) === null || _data$total2 === void 0 ? void 0 : _data$total2.net.amount);
    moneyValue = Number(data === null || data === void 0 ? void 0 : data.paymentToken).toLocaleString(conversorValue, {
      currency: currencyLocal,
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionsDigitsValue
    });
    returnsValue = Number(returnsResult).toLocaleString(conversorValue, {
      currency: currencyLocal,
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionsDigitsValue
    });
  }

  var dateRenderSentAt = "";

  if (lastFulfillmentSentAt) {
    dateRenderSentAt = new Date(lastFulfillmentSentAt).toLocaleString("en-GB", {
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    });
  }

  var dateRenderArrivedAt = "";

  if (lastFulfillmentArrivedAt) {
    dateRenderArrivedAt = new Date(lastFulfillmentArrivedAt).toLocaleString("en-GB", {
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    });
  }

  var dateRenderFinishedAt = "";

  if (lastFulfillmentFinishedAt) {
    dateRenderFinishedAt = new Date(lastFulfillmentFinishedAt).toLocaleString("en-GB", {
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    });
  }

  return React.createElement(React.Fragment, null, React.createElement(S.Wrapper, null, (data === null || data === void 0 ? void 0 : data.status) === "SENT" && React.createElement(React.Fragment, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: function onClick() {
      return navigate(-1);
    }
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNameSendingDelivery)), React.createElement(S.Gif, null, React.createElement("img", {
    alt: "cargando",
    src: assignedImg
  }))), (data === null || data === void 0 ? void 0 : data.status) === "ARRIVED" && React.createElement(React.Fragment, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: function onClick() {
      return navigate(-1);
    }
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNameArrivedDelivery)), React.createElement(S.GifArrived, null, React.createElement("img", {
    alt: "cargando",
    src: arrivedImg
  }))), (data === null || data === void 0 ? void 0 : data.status) === "DELIVERED" && React.createElement(React.Fragment, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: function onClick() {
      return navigate(-1);
    }
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNameDelivered)), React.createElement(S.Images, null, React.createElement(ReactSVG, {
    path: deliveredImg
  }))), (data === null || data === void 0 ? void 0 : data.status) === "UNDELIVERED" && React.createElement(React.Fragment, null, React.createElement(S.Tile, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: function onClick() {
      return navigate(-1);
    }
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNameUnDelivered)), React.createElement(S.Images, null, React.createElement(ReactSVG, {
    path: undeliveredImg
  })), (data === null || data === void 0 ? void 0 : (_data$lastFulfillment4 = data.lastFulfillment) === null || _data$lastFulfillment4 === void 0 ? void 0 : _data$lastFulfillment4.cancellationReason) && React.createElement(S.ParragraphCanceledFulfillment, null, React.createElement(S.SpanCanceledFulfillment, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3077186403",
    defaultMessage: "Reason"
  }), ": "), data === null || data === void 0 ? void 0 : (_data$lastFulfillment5 = data.lastFulfillment) === null || _data$lastFulfillment5 === void 0 ? void 0 : _data$lastFulfillment5.cancellationReason)), (data === null || data === void 0 ? void 0 : data.status) === "CANCELED_FULFILLMENT" && React.createElement(React.Fragment, null, React.createElement(S.TileCanceledFulfillment, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: function onClick() {
      return navigate(-1);
    }
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNameCanceledSending)), React.createElement(S.GifCanceledFulfillment, null, React.createElement("img", {
    alt: "cargando",
    src: canceledFulfillmentImg
  })), (data === null || data === void 0 ? void 0 : (_data$lastFulfillment6 = data.lastFulfillment) === null || _data$lastFulfillment6 === void 0 ? void 0 : _data$lastFulfillment6.cancellationReason) && React.createElement(S.ParragraphCanceledFulfillment, null, React.createElement(S.SpanCanceledFulfillment, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3077186403",
    defaultMessage: "Reason"
  }), ": "), data === null || data === void 0 ? void 0 : (_data$lastFulfillment7 = data.lastFulfillment) === null || _data$lastFulfillment7 === void 0 ? void 0 : _data$lastFulfillment7.cancellationReason)), (data === null || data === void 0 ? void 0 : data.status) === "CANCELED" && React.createElement(React.Fragment, null, React.createElement(S.TileCanceledFulfillment, null, React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement(S.BackTitle, {
    onClick: function onClick() {
      return navigate(-1);
    }
  }, React.createElement(ReactSVG, {
    path: goBackImg
  }), React.createElement(S.BackTitleParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3471378517",
    defaultMessage: "back"
  })))), React.createElement(FormattedMessage, statusMessages.stepNameCanceledDelivery)), React.createElement(S.ImagesCanceled, null, React.createElement(ReactSVG, {
    path: orderNoApprovedImg
  })), (data === null || data === void 0 ? void 0 : data.cancellationReason) && React.createElement(S.ParragraphCanceledFulfillment, null, React.createElement(S.SpanCanceledFulfillment, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3077186403",
    defaultMessage: "Reason"
  }), ": "), data === null || data === void 0 ? void 0 : data.cancellationReason)), React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3426593715",
    defaultMessage: "Customer"
  })), React.createElement(S.Customer, null, React.createElement(S.CustomerData, null, React.createElement(S.CustomerImgData, null, React.createElement(ReactSVG, {
    path: customerImg
  }))), React.createElement(S.CustomerNameData, null, data === null || data === void 0 ? void 0 : (_data$user = data.user) === null || _data$user === void 0 ? void 0 : _data$user.firstName, " "), ((data === null || data === void 0 ? void 0 : data.status) === "SENT" || (data === null || data === void 0 ? void 0 : data.status) === "ARRIVED") && ((data === null || data === void 0 ? void 0 : (_data$user2 = data.user) === null || _data$user2 === void 0 ? void 0 : _data$user2.waNumber) ? React.createElement(Media, {
    maxWidth: xMediumScreen
  }, function (matches) {
    var _data$user3, _data$user4;

    return matches ? React.createElement("a", {
      href: "tel:".concat(data === null || data === void 0 ? void 0 : (_data$user3 = data.user) === null || _data$user3 === void 0 ? void 0 : _data$user3.waNumber)
    }, React.createElement(S.CustomerImage, null, React.createElement(ReactSVG, {
      path: telephoneImg
    }))) : React.createElement(S.CustomerNumber, null, data === null || data === void 0 ? void 0 : (_data$user4 = data.user) === null || _data$user4 === void 0 ? void 0 : _data$user4.waNumber);
  }) : React.createElement(React.Fragment, null))), React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.2758581442",
    defaultMessage: "Shipping Address"
  })), React.createElement(S.Shipping, null, React.createElement(S.ShippingImage, null, React.createElement(ReactSVG, {
    path: shippingImg
  })), React.createElement(S.ShippingData, null, React.createElement(S.ShippingBoldData, null), React.createElement(S.ShippingParragraphData, null, React.createElement(S.ShippingBoldData, null, " ", React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.359810770",
    defaultMessage: "Address"
  }), ":"), " ", address !== "undefined" ? React.createElement(React.Fragment, null, address) : React.createElement(S.SpanSkeleton, null, React.createElement(S.SkeletonPlaceholder, null))), React.createElement(S.ShippingParragraphData, null, React.createElement(S.ShippingBoldData, null, " ", React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1024405385",
    defaultMessage: "Neighborhood"
  }), ":"), " ", neighborhood !== "undefined" ? React.createElement(React.Fragment, null, neighborhood) : React.createElement(S.SpanSkeleton, null, React.createElement(S.SkeletonPlaceholder, null))), observations !== null && (observations === null || observations === void 0 ? void 0 : observations.trim()) !== "" && observations !== "null" && React.createElement(S.ShippingParragraphObservationsData, null, React.createElement(S.ShippingBoldData, null, " ", React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.722592990",
    defaultMessage: "Observations"
  }), ":"), " ", observations !== "undefined" ? React.createElement(React.Fragment, null, observations) : React.createElement(S.SpanSkeleton, null, React.createElement(S.SkeletonPlaceholder, null))))), React.createElement(S.TitleMyOrder, null, React.createElement(S.TitleMyOrderTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3620521256",
    defaultMessage: "Order"
  })), React.createElement(S.TitleMyOrderNumber, null, "# ", data === null || data === void 0 ? void 0 : data.number)), React.createElement(S.Order, null, React.createElement(S.OrderImage, null, React.createElement(ReactSVG, {
    path: cartImg
  })), React.createElement(S.OrderParragraphData, null, React.createElement(S.OrderBoldData, null, " ", amountProducts !== "undefined" ? React.createElement(React.Fragment, null, amountProducts) : React.createElement(S.SkeletonPlaceholder, null)), " ", React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.2968663655",
    defaultMessage: "Products"
  })), React.createElement(S.OrderButton, {
    onClick: goToOrderHistory
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3796752745",
    defaultMessage: "see detail"
  }))), React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.4006712992",
    defaultMessage: "Payment Data"
  })), React.createElement(S.Payment, null, (data === null || data === void 0 ? void 0 : (_data$paymentGateway = data.paymentGateway) === null || _data$paymentGateway === void 0 ? void 0 : _data$paymentGateway.toLowerCase()) === GatewayTypesEnum.CASH ? React.createElement(React.Fragment, null, React.createElement(S.PaymentContainer, null, React.createElement(S.PaymentImage, null, React.createElement(ReactSVG, {
    path: cashImg
  })), React.createElement(S.PaymentData, null, React.createElement(S.PaymentParragraphCashData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1923088536",
    defaultMessage: "Cash"
  })), moneyValue !== "" ? React.createElement(React.Fragment, null, React.createElement(S.PaymentParragraph, null, React.createElement(S.PaymentParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.498277231",
    defaultMessage: "Return for"
  }), ": ", " "), React.createElement(S.PaymentBoldData, null, " ", moneyValue, " ")), returnsValue !== "" && React.createElement(S.PaymentParragraphReturns, null, React.createElement(S.PaymentParragraphDataReturns, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1784788864",
    defaultMessage: "Return"
  }), " ", ": ", " "), React.createElement(S.PaymentBoldDataReturns, null, " ", returnsValue, " "))) : React.createElement(S.PaymentParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.2679669096",
    defaultMessage: "The customer did not specify how much he is going to pay"
  })))), React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3916362286",
    defaultMessage: "Payment status"
  }), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, translatePaymentStatus(data === null || data === void 0 ? void 0 : data.paymentStatus, intl)))) : (data === null || data === void 0 ? void 0 : (_data$paymentGateway2 = data.paymentGateway) === null || _data$paymentGateway2 === void 0 ? void 0 : _data$paymentGateway2.toLowerCase()) === GatewayTypesEnum.DATAPHONE ? React.createElement(React.Fragment, null, React.createElement(S.PaymentContainer, null, React.createElement(S.PaymentDataphoneImage, null, React.createElement(ReactSVG, {
    path: dataphoneImg
  })), React.createElement(S.PaymentData, null, React.createElement(S.PaymentParragraphCashData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1475423084",
    defaultMessage: "Card (Dataphone)"
  })), React.createElement(S.PaymentParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1129098462",
    defaultMessage: "The customer will pay width card"
  })))), React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3916362286",
    defaultMessage: "Payment status"
  }), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, translatePaymentStatus(data === null || data === void 0 ? void 0 : data.paymentStatus, intl))), (data === null || data === void 0 ? void 0 : data.paymentCardType) && React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3107721481",
    defaultMessage: "Card type"
  }), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, cardOptions === null || cardOptions === void 0 ? void 0 : (_cardOptions$find = cardOptions.find(function (option) {
    return option.id === (data === null || data === void 0 ? void 0 : data.paymentCardType);
  })) === null || _cardOptions$find === void 0 ? void 0 : _cardOptions$find.name)), (data === null || data === void 0 ? void 0 : data.paymentConfirmationCode) && React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, (fieldPaymentConfirmationCode === null || fieldPaymentConfirmationCode === void 0 ? void 0 : fieldPaymentConfirmationCode.length) > 0 && ((_fieldPaymentConfirma = fieldPaymentConfirmationCode[0]) === null || _fieldPaymentConfirma === void 0 ? void 0 : _fieldPaymentConfirma.fieldLabel), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, data === null || data === void 0 ? void 0 : data.paymentConfirmationCode)), (data === null || data === void 0 ? void 0 : data.paymentConfirmationFile) && React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1934027242",
    defaultMessage: "Voucher"
  }), ": "), React.createElement(S.VoucherButton, {
    onClick: function onClick() {
      setShowSeeVoucher(!showSeeVoucher);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.370293316",
    defaultMessage: "See voucher"
  })))) : (data === null || data === void 0 ? void 0 : (_data$paymentGateway3 = data.paymentGateway) === null || _data$paymentGateway3 === void 0 ? void 0 : _data$paymentGateway3.toLowerCase()) === GatewayTypesEnum.NEQUI ? React.createElement(React.Fragment, null, React.createElement(S.PaymentContainer, null, React.createElement(S.PaymentDataphoneImage, null, React.createElement(ReactSVG, {
    path: nequiImg
  })), React.createElement(S.PaymentData, null, React.createElement(S.PaymentParragraphCashData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.2137966381",
    defaultMessage: "Nequi"
  })), React.createElement(S.PaymentParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3085543162",
    defaultMessage: "Payment made by Nequi"
  })))), React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3916362286",
    defaultMessage: "Payment status"
  }), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, translatePaymentStatus(data === null || data === void 0 ? void 0 : data.paymentStatus, intl))), (data === null || data === void 0 ? void 0 : data.paymentConfirmationCode) && React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, (fieldPaymentConfirmationCode === null || fieldPaymentConfirmationCode === void 0 ? void 0 : fieldPaymentConfirmationCode.length) > 0 && ((_fieldPaymentConfirma2 = fieldPaymentConfirmationCode[0]) === null || _fieldPaymentConfirma2 === void 0 ? void 0 : _fieldPaymentConfirma2.fieldLabel), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, data === null || data === void 0 ? void 0 : data.paymentConfirmationCode)), (data === null || data === void 0 ? void 0 : data.paymentConfirmationFile) && React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1934027242",
    defaultMessage: "Voucher"
  }), ": "), React.createElement(S.VoucherButton, {
    onClick: function onClick() {
      setShowSeeVoucher(!showSeeVoucher);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.370293316",
    defaultMessage: "See voucher"
  })))) : (data === null || data === void 0 ? void 0 : (_data$paymentGateway4 = data.paymentGateway) === null || _data$paymentGateway4 === void 0 ? void 0 : _data$paymentGateway4.toLowerCase()) === GatewayTypesEnum.ONLINE_CREDIBANCO ? React.createElement(React.Fragment, null, React.createElement(S.PaymentContainer, null, React.createElement(S.PaymentDataphoneImage, null, React.createElement(ReactSVG, {
    path: nequiImg
  })), React.createElement(S.PaymentData, null, React.createElement(S.PaymentParragraphCashData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.213793202",
    defaultMessage: "Online payment"
  })), React.createElement(S.PaymentParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.4032039836",
    defaultMessage: "Paid through credibanco"
  })))), React.createElement(S.PaymentParragraphDataState, null, React.createElement(S.PaymentParragraphDataSpan, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3916362286",
    defaultMessage: "Payment status"
  }), ": "), React.createElement(S.PaymentParragraphDataSpanState, null, translatePaymentStatus(data.paymentStatus, intl)))) : React.createElement(S.SkeletonPlaceholder, null)), (data === null || data === void 0 ? void 0 : data.checkoutObservation) !== null && (data === null || data === void 0 ? void 0 : (_data$checkoutObserva = data.checkoutObservation) === null || _data$checkoutObserva === void 0 ? void 0 : _data$checkoutObserva.trim()) !== "" && (data === null || data === void 0 ? void 0 : data.checkoutObservation) !== "null" && (data === null || data === void 0 ? void 0 : data.checkoutObservation) !== "undefined" && React.createElement(React.Fragment, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.3086091618",
    defaultMessage: "order observations"
  })), React.createElement(S.CheckoutObservation, null, React.createElement(S.CheckoutObservationImage, null, React.createElement(ReactSVG, {
    path: observationsImg
  })), React.createElement(S.CheckoutObservationData, null, (data === null || data === void 0 ? void 0 : data.checkoutObservation) || React.createElement(S.SkeletonPlaceholder, null)))), React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.2303681303",
    defaultMessage: "order value"
  })), React.createElement(S.Total, null, React.createElement(S.TotalImage, null, React.createElement(ReactSVG, {
    path: totalImg
  })), React.createElement(S.TotalData, null, React.createElement(S.TotalTotalData, null, React.createElement(S.TotalTotalParragraphData, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1214258741",
    defaultMessage: "total"
  }), ": "), React.createElement(S.TotalTotalSpanData, null, (data === null || data === void 0 ? void 0 : data.total) ? React.createElement(React.Fragment, null, React.createElement(Money, {
    money: data === null || data === void 0 ? void 0 : (_data$total3 = data.total) === null || _data$total3 === void 0 ? void 0 : _data$total3.net
  })) : React.createElement(S.SkeletonPlaceholder, null))))), React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.2391005400",
    defaultMessage: "Management data"
  })), React.createElement(S.Management, null, React.createElement(S.ManagementImg, null, React.createElement(ReactSVG, {
    path: dateImg
  })), React.createElement(S.ManagementData, null, (data === null || data === void 0 ? void 0 : data.lastFulfillment) ? React.createElement(React.Fragment, null, lastFulfillmentSentAt && React.createElement(S.ManagementContainer, null, React.createElement(S.ManagementParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.99264204",
    defaultMessage: "Date of assignment"
  }), " ", ": "), React.createElement(S.ManagementHour, null, React.createElement(S.ManagementDate, null, " ", dateRenderSentAt, " "))), lastFulfillmentArrivedAt && React.createElement(S.ManagementContainer, null, React.createElement(S.ManagementParragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1654849702",
    defaultMessage: "Arrival date"
  }), " ", ": "), React.createElement(S.ManagementHour, null, React.createElement(S.ManagementDate, null, dateRenderArrivedAt))), lastFulfillmentFinishedAt && React.createElement(S.ManagementContainer, null, React.createElement(S.ManagementParragraph, null, (data === null || data === void 0 ? void 0 : (_data$status = data.status) === null || _data$status === void 0 ? void 0 : _data$status.toUpperCase()) === "DELIVERED" && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.2044221973",
    defaultMessage: "Date of delivery"
  }), ": "), (data === null || data === void 0 ? void 0 : (_data$status2 = data.status) === null || _data$status2 === void 0 ? void 0 : _data$status2.toUpperCase()) === "UNDELIVERED" && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.447657314",
    defaultMessage: "Finish date"
  }), ": "), (data === null || data === void 0 ? void 0 : (_data$status3 = data.status) === null || _data$status3 === void 0 ? void 0 : _data$status3.toUpperCase()) === "CANCELED_FULFILLMENT" && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1086625646",
    defaultMessage: "Cancellation date"
  }), " ", ": "), (data === null || data === void 0 ? void 0 : (_data$status4 = data.status) === null || _data$status4 === void 0 ? void 0 : _data$status4.toUpperCase()) === "CANCELED" && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.StatusDataDelivery.StatusDataDelivery.1086625646",
    defaultMessage: "Cancellation date"
  }), " ", ": ")), React.createElement(S.ManagementHour, null, React.createElement(S.ManagementDate, null, " ", dateRenderFinishedAt, " ")))) : React.createElement(S.SkeletonPlaceholder, null)))), showSeeVoucher && React.createElement(AlertSeeVoucher, {
    hideModal: function hideModal() {
      return setShowSeeVoucher(false);
    },
    paymentConfirmationFile: data === null || data === void 0 ? void 0 : data.paymentConfirmationFile
  }));
};

export { StatusDataDelivery };