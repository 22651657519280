export var ObligatoryFieldEnum;

(function (ObligatoryFieldEnum) {
  ObligatoryFieldEnum["IDENTITY_CARD"] = "IDENTITY_CARD";
  ObligatoryFieldEnum["PAYMENT_CONFIRMATION_CODE"] = "PAYMENT_CONFIRMATION_CODE";
  ObligatoryFieldEnum["PAYMENT_CONFIRMATION_FILE"] = "PAYMENT_CONFIRMATION_FILE";
  ObligatoryFieldEnum["IDENTITY_CARD_TYPE"] = "IDENTITY_CARD_TYPE";
  ObligatoryFieldEnum["EMAIL"] = "EMAIL";
})(ObligatoryFieldEnum || (ObligatoryFieldEnum = {}));

export var OrderErrorCode;

(function (OrderErrorCode) {
  OrderErrorCode["ORDER_NOT_EXISTS"] = "ORDER_NOT_EXISTS";
  OrderErrorCode["CANNOT_ORDER_AGAIN_NOT_TIMEOUT"] = "CANNOT_ORDER_AGAIN_NOT_TIMEOUT";
  OrderErrorCode["CANNOT_CANCEL_ORDER_AND_REFUND"] = "CANNOT_CANCEL_ORDER_AND_REFUND";
  OrderErrorCode["CANNOT_CHANGE_PAYMENT_METHOD"] = "CANNOT_CHANGE_PAYMENT_METHOD";
  OrderErrorCode["CUSTOMER_NOT_ACTIVE"] = "CUSTOMER_NOT_ACTIVE";
  OrderErrorCode["CUSTOMER_NOT_SAME"] = "CUSTOMER_NOT_SAME";
  OrderErrorCode["CANNOT_ORDER_AGAIN_CUSTOMER_HAS_IN_PROGRESS"] = "CANNOT_ORDER_AGAIN_CUSTOMER_HAS_IN_PROGRESS";
  OrderErrorCode["TIME_IS_OVER"] = "TIME_IS_OVER";
})(OrderErrorCode || (OrderErrorCode = {}));

export var OrderStatus;

(function (OrderStatus) {
  OrderStatus["ARRIVED"] = "ARRIVED";
  OrderStatus["CANCELED"] = "CANCELED";
  OrderStatus["CANCELED_FULFILLMENT"] = "CANCELED_FULFILLMENT";
  OrderStatus["DELIVERED"] = "DELIVERED";
  OrderStatus["DRAFT"] = "DRAFT";
  OrderStatus["DRAFT_NEW_ORDER"] = "DRAFT_NEW_ORDER";
  OrderStatus["FULFILLED"] = "FULFILLED";
  OrderStatus["PENDING"] = "PENDING";
  OrderStatus["SENT"] = "SENT";
  OrderStatus["PARTIALLY_FULFILLED"] = "PARTIALLY_FULFILLED";
  OrderStatus["UNDELIVERED"] = "UNDELIVERED";
  OrderStatus["UNFULFILLED"] = "UNFULFILLED";
  OrderStatus["TIMEOUT"] = "TIMEOUT";
  OrderStatus["CANCELLED_BY_CUSTOMER"] = "CANCELLED_BY_CUSTOMER";
  OrderStatus["CANCELLED_BY_SYSTEM"] = "CANCELLED_BY_SYSTEM";
  OrderStatus["SCHEDULED"] = "SCHEDULED";
  OrderStatus["REFUSED_PAYMENT"] = "REFUSED_PAYMENT";
})(OrderStatus || (OrderStatus = {}));

export var GatewayTypesEnum;

(function (GatewayTypesEnum) {
  GatewayTypesEnum["CASH"] = "mirumee.payments.cash";
  GatewayTypesEnum["DATAPHONE"] = "mirumee.payments.dataphone";
  GatewayTypesEnum["NEQUI"] = "mirumee.payments.nequi";
  GatewayTypesEnum["ONLINE_CREDIBANCO"] = "easypedido.payments.online_credibanco";
})(GatewayTypesEnum || (GatewayTypesEnum = {}));

export var UserRoleEnum;

(function (UserRoleEnum) {
  UserRoleEnum["SUPER_ADMIN"] = "SUPER_ADMIN";
  UserRoleEnum["STAFF"] = "STAFF";
  UserRoleEnum["MAINTRADE_STAFF"] = "MAINTRADE_STAFF";
  UserRoleEnum["TRADE_STAFF"] = "TRADE_STAFF";
  UserRoleEnum["CUSTOMER"] = "CUSTOMER";
  UserRoleEnum["DELIVERY_MAN"] = "DELIVERY_MAN";
})(UserRoleEnum || (UserRoleEnum = {}));

export var CheckoutErrorsEnum;

(function (CheckoutErrorsEnum) {
  CheckoutErrorsEnum["INSUFFICIENT_STOCK"] = "INSUFFICIENT_STOCK";
  CheckoutErrorsEnum["SHIPPING_METHOD_NOT_SET"] = "SHIPPING_METHOD_NOT_SET";
  CheckoutErrorsEnum["NOT_SUPPORTED_GATEWAY"] = "NOT_SUPPORTED_GATEWAY";
  CheckoutErrorsEnum["PAYMENT_ONLINE_ERROR"] = "PAYMENT_ONLINE_ERROR";
  CheckoutErrorsEnum["CONNECTIONERROR"] = "CONNECTIONERROR";
})(CheckoutErrorsEnum || (CheckoutErrorsEnum = {}));

export var PaymentChargeStatusEnum;

(function (PaymentChargeStatusEnum) {
  PaymentChargeStatusEnum["NOT_CHARGED"] = "NOT_CHARGED";
  PaymentChargeStatusEnum["PENDING"] = "PENDING";
  PaymentChargeStatusEnum["PARTIALLY_CHARGED"] = "PARTIALLY_CHARGED";
  PaymentChargeStatusEnum["FULLY_CHARGED"] = "FULLY_CHARGED";
  PaymentChargeStatusEnum["PARTIALLY_REFUNDED"] = "PARTIALLY_REFUNDED";
  PaymentChargeStatusEnum["FULLY_REFUNDED"] = "FULLY_REFUNDED";
  PaymentChargeStatusEnum["REFUSED"] = "REFUSED";
  PaymentChargeStatusEnum["CANCELED"] = "CANCELED";
  PaymentChargeStatusEnum["REFUND_FAILED"] = "REFUND_FAILED";
  PaymentChargeStatusEnum["VOID"] = "VOID";
})(PaymentChargeStatusEnum || (PaymentChargeStatusEnum = {}));

export var PermissionEnum;

(function (PermissionEnum) {
  PermissionEnum["FULFILLMENT_SELF_ASSIGN"] = "FULFILLMENT_SELF_ASSIGN";
})(PermissionEnum || (PermissionEnum = {}));